@import '../../../theme.scss';

.question-summary-component {
  background-color: #13276F;
  padding: 25px;
  color: $white900;

  .right-description-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      opacity: 0.8;
    }

    .num-wrapper {
      background: no-repeat url('../../../Assets/PracticeQuestions/Summary-bg.png');
      background-position: center;
      background-size: contain;
      padding: 35px;
      margin-bottom: -15px;

      .questions-num {
          color: $green700;
        }
    }
  }

  .btn-2 {
    background-color: $green700;
    margin: 20px 0 30px 0;
  }

  .btn-3 {
    background-color: $green700;
  }
}