@import '../../../theme.scss';

.brochure-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .brochure-container {
        display: flex;
        flex-direction: column;        
        .title {
            text-align: center;
            font-weight: bold;
        }
        .description {
            text-align: center;
            font-size: 20px;
        }
        .main-row {
            .left-area {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .right-area {
                background: no-repeat url('../../../Assets/Home/about-bg.png');
                background-position: center;
                background-size: 100% 100%;
                .image-container {
                    width: 70%;
                    margin: auto;
                }
                img {
                    position: relative;
                    width: 100%;
                    z-index: 1;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .brochure-component {
        padding: 50px 0;
        .brochure-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }
            .main-row {
                padding: 30px 0px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .brochure-component {
        padding: 0px 0 62px;
        .brochure-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;
                .left-area {
                    .start-btn {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}