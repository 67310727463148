@import '../../../theme.scss';

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(-50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}

.homestart-v2-component {
    color: $dark-color;    
    padding-top: 40px;
    
    h1 {
        font-weight: 700;
        animation: contentShow 0.3s ease-out;
    }
    .strong-bg {
        background: no-repeat url('../../../Assets/HomeV2/strong-bg-1.png');
        background-position: center bottom;
        background-size: 100% 100%;
        padding: 5px 10px;
    }
    .strong-bg-2 {
        background: no-repeat url('../../../Assets/HomeV2/strong-bg-2.png');
        background-position: center bottom;
        background-size: 100% 40%;
        // padding: 5px 0px;
    }
    .no-credit {
        width: 185px;
        height: 30px;
        background-color: $red-color;
        font-size: 14px;
        color: #FFF;        
    }
    .request-btn {
        background-color: $v2-color;
        color: #FFF;
        height: 60px;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
    }
    .no-btn {
        border: solid 1px $dark-color;
        color: $dark-color;
        background-color: transparent;
    }
    .sub-description {        
        font-weight: 400;
        background-color: #FFF;
    }
    .left-area {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }
}

@media screen and (min-width: 992px) {
    .homestart-v2-component {
        display: flex;
        align-items: center;
        height: 700px;
        margin-top: -70px;
        background: no-repeat url('../../../Assets/HomeV2/homeStartV2-bg.png');
        background-position: center bottom;
        background-size: 100% 100%;
        h1 {
            font-size: 36px;
            line-height: 46px;
        }
        .main-content {
            background: no-repeat url('../../../Assets/HomeV2/content-bg.png');
            background-position: center bottom;
            background-size: 100% 100%;
            margin-top: 70px !important;
        }
        .description {
            margin-top: 103px;
            padding-right: 50px;
        }
        .request-btn {
            width: 210px;
        }
        .no-btn {
            margin-left: 20px;
        }
        .sub-description {
            font-size: 20px;
            padding-left: 15px;
            margin-top: 33px;
            margin-bottom: 95px;
            padding: 5px 10px;
        }      
    }
}

@media screen and (max-width: 991px) {
    .homestart-v2-component {
        margin-top: -70px;
        padding-top: 70px;
        background: no-repeat url('../../../Assets/HomeV2/homeStartV2-bg.png');
        background-position: right bottom;
        background-size: cover;
        text-align: center;
        h1 {
            font-size: 24px;
            line-height: 34px;
        }
        .description {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
        }
        .request-btn-container {
            width: 100%;
            justify-content: space-between;
            .request-btn {
                width: 158px;
            }
        }
        .sub-description {
            height: 40px;
            font-size: 14px;
            width: 100%;
            margin-top: 20px;
        }
        .left-area {
            display: none;
        }
    }
}