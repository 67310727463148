@import '../../../theme.scss';

.why-tutor-v2-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $dark-color;

    .why-tutor-container {
        display: flex;
        flex-direction: column;

        h1 {
            text-align: center;
            font-weight: bold;
        }
        .main-row {
            .left-area, .right-area {
                .item-area {
                    min-height: 80px;
                    display: flex;
                    align-items: center;
                    background-color: #FFF;
                    box-shadow: 0px 5px 10px rgba(3, 45, 30, 0.05);
                    margin: 30px 0;
                    padding: 20px 15px;
                    
                    img {
                        width: 40px;
                    }   
                    .title {
                        font-weight: 500;
                        font-size: 18px;
                    }
                    .description {
                        font-weight: 400;
                        font-size: 15px;
                        display: none;
                    }
                    &:hover {
                        align-items: flex-start;
                        border: solid 0.5px $primary-color;
                        box-shadow: 2px 1px 20px 6px rgba(35,164,239,0.11);
                        .title {
                            color: $primary-color;
                            font-weight: 700;
                        }
                        .description {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .why-tutor-v2-component {
        min-height: 766px;
        padding: 70px 0;
        .why-tutor-container {
            h1 {
                font-size: 36px;
                margin-bottom: 48px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .why-tutor-v2-component {
        padding: 20px 0;
        .why-tutor-container {
            h1 {
                font-size: 24px;
            }
        }
    }
}