@import '../../../theme.scss';

@import '../../../theme.scss';

.subscribe-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .subscribe-content {
                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                display: flex;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    .icon-close {
                        transform: rotate(45deg);
                    }
                }
               
                .content-area {
                    h2 {
                        font-weight: 700;
                        margin-top: 0px;
                    }
                    
                    p{
                        font-weight: 700;
                        margin-top: 30px;

                    }
                    .subscribe-btn-area{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                        .subscribe-btn {
                            font-weight: 500;
                            border-radius: 4px;
                            color: white;
                            min-height: 50px;
                            border: none;
                            outline: none;
                            font-family: inherit;
                            background: #23A4EF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }

                    .textarea-note {
                        width: 100%;
                        height: 60px;
                        font-size: 20px;
                        border: 2px solid #E3E8EC;
                        border-radius: 4px;
                        padding: 5px 10px;
                        resize: none;
                        margin-bottom: 15px;
                    }

                    .textarea-note-error {
                        border: 2px solid red;
                    }
                }

                .subscribe-policy {
                    font-size: 14px;
                    margin-top: 20px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .subscribe-content {
        max-width: 700px;
        
        .content-area {
            padding: 37px 45px 45px;
            flex: 1;

            h2 {
                font-size: 30px;
                margin-top: 34px;
            }

            h3 {
                font-size: 20px;
                margin-top: 20px;
            }

            p {
                font-size: 15px;
            }

            .subscribe-btn-area{
                .subscribe-btn {
                    width: 45%;
                    font-size: 20px;
                }
            }
            
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .subscribe-content {
        max-width: 345px;
        flex-direction: column;
        
        .content-area {
            padding: 19px 30px 30px;
           
            h2 {
                font-size: 20px;
                margin-top: 21px;
            }

            h3 {
                font-size: 16px;
                margin-top: 15px;
            }

            p {
                font-size: 10px;
            }
            .subscribe-btn-area{
                .subscribe-btn {
                    width: 60%;
                    font-size: 14px;
                }
            }
        }
    }
}
