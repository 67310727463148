@import '../../../../theme.scss';

.topic-item-wrapper {
  margin: 29px 0;
  position: relative;

  .topic-item-component {
      padding: 24px;
      font-size: 16px;
      background-color: white;

      .question-title {
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .question-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .open-close-icon {
          cursor: pointer;
        }
      }

      .question-body {
        width: 90%;

        .read-more-btn {
          font-size: 14px;
          padding: 10px 20px;
          display: inline-block;
          background-color: $blue800_10;
          color: $blue800;
          margin-top: 10px;
        }
      }
    }

    .dotted-bg {
      background: no-repeat url('../../../../Assets/PracticeQuestions/topic-bg.png');
      background-position: center right;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 21px;
      right: -13px;
      z-index: -1;
    }
}