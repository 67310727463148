@import '../../../theme.scss';

.startarea-component {
    font-family: Maax;
    margin-top: -70px;
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;

    .startarea-content {
        .startarea-container {
            .main-row {
                .left-area {
                    h1 {
                        font-weight: bold;
                        margin: 0;
                        color: #203662;
                    }

                    p {
                        font-weight: 400;
                        margin: 0;
                        color: #203662;
                    }

                    .free-highlight {
                        background-color: red;
                        padding: 5px;
                        color: #FFF;
                        border-radius: 5px;
                        font-weight: 400;
                    }

                    .credit-highlight {
                        margin-top: 10px;
                        background-color: yellow;
                        padding: 5px;
                        color: #000;
                        border-radius: 5px;
                        font-weight: 400;
                    }
                }
            }

            .logo-area {
                justify-content: space-between;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .startarea-component {
        background: no-repeat url('../../../Assets/Home/index-bg1.jpg');
        background-position: center;
        background-size: 100% 100%;

        .startarea-content {
            padding: 70px 0 30px;
            display: flex;
            flex-direction: column;

            .startarea-container {
                display: flex;
                flex-direction: column;
                padding-top: 90px;

                .main-row {
                    display: flex;
                    .left-area {
                        justify-content: start;
                        .left-text {
                            padding-right: 50px;
                        }
                        h1 {
                            font-size: 35px;
                            line-height: 57px;
                            color: #203662;                            
                        }
                        .first-lesson {
                            // display: flex;
                            // align-items: center;
                            padding-top: 30px;
                            p {
                                font-size: 35px;
                                line-height: 40px;
                                color: #203662;
                            }
                            .free-text {
                                color: white;
                                margin-left: -110px;
                            }                                                       
                        }
                        .pay-startBtn{
                            margin-top: 35px;
                            margin-bottom: 65px;
                            padding-right: 150px;
                            .pay-afford {
                                    
                                p {
                                    font-size: 24px;
                                    font-weight: bold;
                                    margin-bottom: 30px;
                                    color: #203662;
                                }
                                .display-hourlyRate {
                                    font-size: 13px;
                                    font-weight: normal;
                                }
                            }
        
                            .form-area {
                                margin-top: 43px;                                
                            }
                        }                      
                    }

                    .right-area {
                        img {
                            width: 100%;
                        }
                    }
                }                
            }

            .logo-area {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .startarea-component {
        background: no-repeat url('../../../Assets/Home/index-bg1.jpg');
        background-position: -430px 0;
        background-size: cover;
        
        .startarea-content {
            padding-top: 70px;
            .startarea-container {
                padding: 70px 0 30px;

                .main-row {
                    margin: 0;
                    .left-area {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .left-text {
                            h1 {
                                font-size: 34px;
                                line-height: 40px;
                                color: #203662;     
                                text-align: center;                       
                            }
                        }
                        
                        .first-lesson {
                            padding-top: 30px;
                            p {
                                font-size: 25px;
                                line-height: 40px;
                                color: #203662;
                                text-align: center;
                            }
                            .free-text {
                                color: white;
                                margin-left: -97px;
                                font-size: 34px;
                            }
                        }

                        .pay-afford {
                            p {
                                font-size: 24px;
                                font-weight: bold;
                                margin-bottom: 30px;
                            }
                        }
    
                        .form-area {
                            margin-top: 43px;
                        }
                        .pay-startBtn {
                            margin-top: 30px;
                            margin-bottom: 40px;
                            .pay-afford {
                                padding: 20px;
                                p {
                                    font-size: 24px;
                                    font-weight: bold;
                                    margin-bottom: 30px;
                                    color: #203662;
                                    text-align: center;
                                }
                                .display-hourlyRate {
                                    font-size: 15px;
                                    font-weight: normal;
                                }                                
                            }
        
                            .form-area {
                                .start-btn {
                                    margin-left: auto;
                                    margin-right: auto;
                                }                                
                            }
                        }
                    }

                    .right-area {
                        margin-top: 30px;
                        img {
                            width: 100%;
                        }
                    }
                }                
            }

            .logo-area {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    height: 20px;
                }
            }
        }
    }
}