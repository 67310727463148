@import '../../../theme.scss';

.location-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .location-container {
        display: flex;
        flex-direction: column;        
        .title {
            text-align: center;
            font-weight: bold;
        }
        .main-row {
            .left-area {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;                
                .location-details {
                    font-size: 15px;
                    margin-bottom: 30px;   
                }
            }
            .right-area {
                .map-wrapper {
                    width: 100%;
                    padding-top: 75%;
                    position: relative;    
                    .map-container {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }        
        .start-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .location-component {
        padding: 70px 0;
        .location-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }
            .main-row {
                padding: 30px 0px;
            }
            .start-btn {
                margin-top: 120px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .location-component {
        padding: 0px 0 62px;
        .location-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;
                .left-area {
                    margin-top: 30px;
                }
            }
            .start-btn {
                margin-top: 74px;
            }
        }
    }
}