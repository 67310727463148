@import '../../../theme.scss';

.video-item-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: relative;
    height: 100%;

    h2 {
        font-size: 23px;
        font-weight: 400;
        margin: 15px 0px;
    }

    h3 {
        font-size: 20px;
        font-weight: 400;
    }

    .video-container {
        .video-item {
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            background: #000;
            position: relative;
            z-index: 1;
            cursor: pointer;

            .video-image {
                position: relative;
                border: solid 1px #FFF;

                .image-size {
                    width: 100%;
                    height: 100%;
                }

                .play-btn-bg {
                    position: absolute;
                    z-index: 10;
                    left: calc(50% - 45px);
                    top: calc(50% - 30px);
                    width: 90px;
                    height: 60px;
                    background-color: $secondary-bg;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .play-icon {
                        font-size: 45px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .video-item-component {
        .video-container {
            h2 {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .video-item {
                height: 400px;
                display: flex !important;
                flex-direction: column;
                justify-content: center;

                .video-image {
                    // min-height: 266px;
                }
            }

            &:hover {
                -ms-transform: scale(1.05);
                /* IE 9 */
                // -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.05);
                transition: 1s;
                z-index: 9;

                h2 {
                    display: block;
                }

                .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .video-item-component {
        .video-container {
            .video-item {
                .video-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // min-height: 165px;
                }
            }
        }
    }
}