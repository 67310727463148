@import '../../../theme.scss';

.transparency-component {
    font-family: Maax;
    display: flex;
    position: relative;

    flex-direction: column;
    color: $secondary-text;


    .transparency-container {
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-weight: bold;
        }

        .row-container {
            position: relative;
        }

        .main-row {
            position: relative;
            background: white;
            z-index: 1;
            .col-area {
                display: flex;
                align-items: center;
                flex-direction: column;
                
                .price-table {
                    box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                    background: white;
                    z-index: 2;
                    position: relative;
                    background: white;
                    .table-item {
                        display: flex;
                        align-items: center;
                        p {
                            font-weight: bold;
                        }    
                        .table-left {
                            padding: 20px 0 20px 20px;
                            border-right: #E7EBEE solid 2px;
                        }
                        .table-left1 {
                            padding: 20px 0 20px 20px;
                            border-right: #E7EBEE solid 2px;
                            background: #F9FAFB;
                        }
                        .table-right {
                            padding: 20px 0 20px 20px;
                        }   
                        .table-right1 {
                            padding: 20px 0 20px 20px;
                            background: #F9FAFB;
                        }
                        .total-cost {
                            font-size: 21px;
                            font-weight: bold;
                        }
                    }
                }
            }
               
        }
        .start-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .transparency-component {
        padding: 105px 0 108px;
        .transparency-container {
            .title {
                font-size: 34px;
                margin-bottom: 33px;
            }
            .description {
                font-size: 20px;
                margin-bottom: 33px;
            }
            .main-row {
                display: flex;
                align-items: center;
                .col-area {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    
                    .price-table {
                        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                        background: white;
                        z-index: 2;
                        position: relative;
                        background: white;
                        width: 100%;
                        .table-item {
                            display: flex;
                            align-items: center;
                            p {
                                font-weight: bold;
                            }    
                            .table-left {
                                padding: 20px 0 20px 20px;
                                border-right: #E7EBEE solid 2px;
                            }
                            .table-left1 {
                                padding: 20px 0 20px 20px;
                                border-right: #E7EBEE solid 2px;
                                background: #F9FAFB;
                            }
                            .table-right {
                                padding: 20px 0 20px 20px;
                            }   
                            .table-right1 {
                                padding: 20px 0 20px 20px;
                                background: #F9FAFB;
                            }
                            .total-cost {
                                font-size: 21px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            .start-btn {
                
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .transparency-component {
        padding: 73px 0 78px;
        .transparency-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                margin: 0;
                .col-area {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    
                    .price-table {
                        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                        background: white;
                        z-index: 2;
                        position: relative;
                        background: white;
                        width: 100%;
                        .table-item {
                            display: flex;
                            align-items: center;
                            p {
                                font-weight: bold;
                            }    
                            .table-left {
                                padding: 20px 0 20px 20px;
                                border-right: #E7EBEE solid 2px;
                            }
                            .table-left1 {
                                padding: 20px 0 20px 20px;
                                border-right: #E7EBEE solid 2px;
                                background: #F9FAFB;
                            }
                            .table-right {
                                padding: 20px 0 20px 20px;
                            }   
                            .table-right1 {
                                padding: 20px 0 20px 20px;
                                background: #F9FAFB;
                            }
                            .total-cost {
                                font-size: 21px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            .start-btn {
                margin-top: 40px;
            }
        }
    }
}