@import '../../../theme.scss';

.pq-subject-header-component {
    padding: 40px 0;
    color: $white900;
    margin-top: 40px;

    h1 {
        font-size: 44px;
        font-weight: 700;
        white-space: pre-line;
        line-height: 60px;
        padding: 5px 0 10px;
    }

    .subject-start-container {
        background: no-repeat url('../../../Assets/PracticeQuestions/subject-header-bg.png');
        background-position: center;

        .left-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 20px;
        }

        .right-content {
            padding-right: 0;

            .header-img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .subject-header-component {
        padding: 0;

        h1 {
            font-size: 24px;
            text-align: center;
            line-height: 50px;
        }

        .left-content {
            padding-top: 30px;
        }
    }
}