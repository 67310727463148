@import '../../../theme.scss';

.review-component {
    background: white;
    width: 100%;
    color: $dark-color;
    box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.05);
    .review-header {
        display: flex;
        .avatar {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-right: 15px;
            color: #FFF;
            font-size: 25px;
            font-weight: 700;
            &.user-0 {
                background: #24C1A5;
            }
            &.user-1 {
                background: #4624C1;
            }
            &.user-2 {
                background: #C124B4;
            }
        }
        .reviewer-info {
            flex: 1;
        }
        h3 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 4px;
            img {
                margin-left: 4px;
                width: 17px;
                height: 17px;
            }
        }
        .address {
            font-size: 14px;
            margin-bottom: 6px;           
        }
    }
    h2 {
        margin: 27px 0 6px;
    }
    p {
        font-size: 13px;
    }
    .star-area {
        padding: 10px 0px;
        border-bottom: solid 1px $gray-color;
    }
    .feedback-title {
        font-size: 13px;
        font-weight: bold;
        margin: 10px 0;
    }
    .review {
        font-size: 15px;
        line-height: 26px;
        height: 182px;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        margin: 0px 0 6px;
    }
    .continue-btn {
        cursor: pointer;
        color: $primary-color;
        text-decoration: underline;
        margin-top: 4px;
    }
}

@media screen and (min-width: 992px) {
    .review-component {
        padding: 30px 20px 20px 30px;
        max-width: 370px;
    }
}

@media screen and (max-width: 991px) {
    .review-component {
        padding: 30px 9px 24px 30px;
        min-width: 335px;
    }
}