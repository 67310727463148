@import '../../../theme.scss';

.service-component {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    .info-area {
        padding: 10px;
        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        position: relative;
        z-index: 1;

        .item-txt {
            color: #007bff;
            font-size: 15px;
            padding: 10px;
            cursor: pointer;
        }
    }

    .custom-dot {
        right: 17px;
        left: 17px;
        top: 20px;
        bottom: -12px;
        width: unset;
        height: unset;
    }
}