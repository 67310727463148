@import '../../../../theme.scss';

.question-item-component {
  margin: 20px 0;
  padding: 30px;
  font-size: 16px;

  .question-title {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .open-close-icon {
      cursor: pointer;
    }
  }

  .question-body {
    width: 90%;

    .read-more-btn {
      font-size: 14px;
      padding: 10px 20px;
      display: inline-block;
      background-color: $blue800_10;
      color: $blue800;
      margin-top: 10px;
    }
  }

}