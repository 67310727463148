@import '../../../theme.scss';

.inputbox-component {
    width: 100%;
    position: relative;

    .phoneInput-container {
        position: relative;
        font-family: Maax;
        width: 100%;
        display: flex;
        align-items: center;
        border: 2px solid #E7EBEE;
        background: white;
        z-index: 2;

        .phone {
            border-width: 0px;
            font-family: Maax;
            color: black;
            &:focus {
                box-shadow: none;
            }
        }

        .drop-down {

        }

        .buttonClass {
            background-color: transparent;
            border-width: 0px;
            margin-left: 10px;
        }

        ::placeholder {
            color: #C5CBD7;
        }
    
        .search-text {
            flex: 1;
            height: 100%;
            border: none;
            outline: none;
            background: none;

            &.no-icon {
                padding-left: 0;
            }
        }
    }

    .phoneInput-container-error {
        border: 2px solid red;
    }

    .inputbox-bg {
        bottom: initial;
    }
}

@media screen and (min-width: 992px) {
    .phoneInput-container {
        height: 65px;
        padding-left: 22px;

        .phone {
            font-size: 20px;
        }

        .buttonClass {
                
        }

        .search-text {
            font-size: 20px;
            padding-left: 16px;
        }
    }

    .inputbox-bg {
        height: 65px;
        left: 12px;
        top: 14px;
        right: -12px;
    }
}

@media screen and (max-width: 991px) {
    .phoneInput-container {
        height: 50px;
        padding-left: 17px;

        .phone {
            font-size: 16px;
        }

        .dropdown {
            width: 254px;
            z-index: 3;
        }

        .search-text {
            font-size: 16px;
            padding-left: 14px;
        }
    }
    
    .inputbox-bg {
        height: 50px;
        left: 8px;
        top: 8px;
        right: -8px;
    }
}