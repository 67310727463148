@import '../../theme.scss';

.auth-page {
    background-color: white;
    min-height: 100vh;
    width: 100%;
    margin-top: -70px;
    display: flex;
    font-family: Maax;

    .ads-area {
        color: white;
        display: flex;
        flex-direction: column;
        position: relative;
        background: no-repeat url('../../Assets/Auth/adsbg.png');
        background-position: center;

        p {
            margin: 0;
        }

        .subtitle {
            font-size: 13px;
            font-weight: 500;
        }

        .support-area {
            position: absolute;
            left: 31px;
            right: 31px;
            bottom: 30px;
            height: 20px;
            justify-content: space-between;
            img {
                height: 100%;
            }
        }

        .btn-element {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    .auth-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .main-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
        }
    }
}

@media screen and (max-width: 1200px) {
    .auth-page {
        .auth-content {
            .main-col {
                padding: 0 15px !important;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .auth-page {
        .ads-area {
            width: 492px;
            background-size: cover;
            color: white;
            justify-content: center;
            text-align: center;
            padding: 0 43px;
            font-size: 15px;
    
            h1 {
                margin: 32px 0 29px;
                font-size: 28px;
                line-height: 37px;
                font-weight: bold;
            }
    
            .explain {
                font-size: 15px;
                line-height: 24px;
            }

            .read-btn {
                cursor: pointer;
                display: flex;
                height: 50px;
                border: 2px solid $primary-text;
                margin: 39px auto 0 auto;
                color: white;
                font-weight: bold;

                .btn-text {
                    border-right: 2px solid $primary-text;
                    width: 137px;
                }

                .btn-arrow {
                    width: 50px;
                }
            }
        }

        .auth-content {
            .main-col {
                padding: 0 117px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .auth-page {
        flex-direction: column-reverse;        

        .ads-area {
            background-size: 100% auto;
            padding: 30px;

            .subtitle {
                margin-bottom: 14px;
            }

            .explain-mobile {
                justify-content: space-between;
                align-items: center;

                h1 {
                    margin: 0;
                    font-weight: bold;
                    line-height: 20px;
                    font-size: 15px;
                    flex: 1;
                }

                .btn-arrow {
                    width: 40px;
                    height: 40px;
                    border: 2px solid $primary-text;
                }
            }
        }
    }
}