@import '../../../theme.scss';

.homev2-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .homev2-content {
                input {
                    border: none;
                    padding-left: 18px;
                    padding-right: 18px;
                    font-size: 17px;
                    width: 100%;
                    background-color: transparent;
            
                }
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    padding: 5px;
                }

                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-close {
                        width: 100%;
                        height: 100%;
                        div {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transform: rotate(90deg);
                        }
                    }
                }
                .title {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                    line-height: 23px;
                }
                .help-title {
                    font-size: 18px;
                }
                .cagegory-content {
                    width: 30px;
                    height: 30px;
                    font-weight: 20px;
                    font-family: bold;
                    border: solid 1px $dark-color;
                }
                .call-back-btn {
                    background-color: $primary-color;
                    cursor: pointer;
                    color: #FFF;
                    border-radius: 5px;
                    padding: 5px 10px;
                }
                .submit-area {
                    display: flex;
                    .comment-container {
                        width: 100%;
                        .comment-area {
                            border: solid 1px $dark-color;
                            .input-container {
                                width: 100%;
                            }
                            .input-header {
                                height: 40px;
                                border-bottom: solid 1px $dark-color;
                            }
                            .text-area {
                                padding: 5px;
                            }
                        }
                        .submit-btn {
                            width: 100%;
                            background-color: $primary-color;
                            height: 40px;
                            color: #FFF;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .homev2-content {
        max-width: 689px;
        height: 438px;
        padding: 20px;
        
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .homev2-content {
        max-width: 345px;
        flex-direction: column;
        max-height: 445px;
        height: 100%;
        padding: 15px;
        .call-me-txt {
            font-size: 12px;
        }
        .call-back-btn {
            font-size: 12px;
        }
    }
}