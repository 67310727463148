@import '../../../../theme.scss';

.bg-item-wrapper {
    position: relative;
    margin: 20px 0;

    .resource-item-component {
        padding: 20px;
        font-size: 16px;
        background-color: white;

        .resource-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .file-info {
                display: flex;
                align-items: center;

                .info {
                    .resource-title {
                        font-weight: 700;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
            
                    .resource-size {
                        font-size: 10px;
                        color: $gray600;
                    }
            
                    margin: 0 10px;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .dotted-bg {
        position: absolute;
        background: no-repeat url('../../../../Assets/PracticeQuestions/dotted-bg.png');
        background-position: center right;
        width: 100%;
        height: 100%;
        top: 20px;
        right: -15px;
        z-index: -1;
    }
}