@import '../../../theme.scss';

.faqs-component {
    display: flex;
    flex-direction: column;
    font-family: Maax;
    color: $secondary-text;

    .faqs-container {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-weight: bold;
        }

        .faqs-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: white;

            .accordion {
                border-bottom: 1px solid #E7EBEE;
                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .start-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 1200px) {
    .faqs-component {
        .faqs-container {
            max-width: 930px;

            .start-btn {
                margin-top: 120px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .faqs-component {
        padding: 50px 0px;
        .faqs-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .faqs-list {
                padding: 0 30px;
            }

            .start-btn {
                margin-top: 120px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .faqs-component {
        padding: 66px 0 0px;
        .faqs-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }

            .faqs-list {
                padding: 0 15px;
            }

            .start-btn {
                margin-top: 74px;
            }
        }
    }
}