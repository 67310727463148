@import '../../../theme.scss';

.question-description-component {
  img {
    width: 100%;
  }

  p {
    font-size: 16px;
    margin: 15px 0;
  }
}