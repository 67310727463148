@import '../../../theme.scss';

.practice-test-header-component {
    padding: 40px 0;
    margin-top: 40px;
    color: $white900;

    h1 {
        font-size: 44px;
        font-weight: 700;
        white-space: pre-line;
        line-height: 60px;
        padding: 5px 0 10px;
    }

    .subject-start-container {
        background: no-repeat url('../../../Assets/PracticeTest/Header-bg.png');
        background-position: center;

        .left-content {
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 20px;
            padding: 20px 0 20px 2rem;

            .bread-crumb {
                color: white;
                cursor: pointer;
            }

            span {
                font-size: 12px;
                font-weight: 500;
                margin-right: 15px;
            }

            .opacity {
                opacity: 0.8;
            }
        }

        .right-content {
            padding-right: 0;

            .header-img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .subject-header-component {
        padding: 0;

        h1 {
            font-size: 24px;
            text-align: center;
            line-height: 50px;
        }

        .left-content {
            padding-top: 30px;
        }
    }
}