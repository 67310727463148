@import '../../../theme.scss';

.local-partners-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .local-partners-container {
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;
        }

        .main-row {
            padding: 0;
            .owl-theme {
                .owl-nav {
                    .owl-prev, .owl-next {
                        outline: none;
                        &:hover {
                            background: none;
                        }
                    }
                }
                .owl-stage-outer {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    .owl-stage {
                        display: flex;
                    }
                }
            }   
        }
    }
}

@media screen and (min-width: 992px) {
    .local-partners-component {
        padding: 50px 0;
        .local-partners-container {
            padding: 0px;

            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                .owl-theme {
                    .owl-nav {
                        position: absolute;
                        display: flex;
                        justify-content: space-between;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: 0;
                        left: -30px;
                        right: -30px;
                    }
                }
               
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .local-partners-component {
        padding: 0px 0 62px;
        .local-partners-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                .owl-theme {
                    .owl-nav {
                        .owl-prev, .owl-next {
                            outline: none;
                            &:hover {
                                background: white;
                            }
                        }
                        .owl-prev {
                            margin: 0 15px 0 5px;
                        }
                        .owl-next {
                            margin: 0 5px 0 15px;
                        }
                    }
                }
            }
        }
    }
}