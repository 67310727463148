.v-r {
  display: flex;
  flex-direction: column;
}

.v-c {
  display: flex;
  align-items: center;
}

.h-c {
  display: flex;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
}

.svg-icon {
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
}

.shadow-object {
  box-shadow: 0px 14px 30px rgba(3, 45, 30, 0.21);
}

.shadow-object-2 {
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.btn-2 {
  font-weight: 900;
  font-size: 15px;
  border-radius: 4px;
  color: white;
  min-height: 40px;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  font-family: inherit;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    color: white;
  }
}

.btn-3 {
  font-size: 18px;
  color: $white900;
  padding: 15px 35px;
  background-color: $blue800;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    color: white;
    opacity: 0.8;
  }
}

.btn-2-outline {
  background: none;
  border: 1px solid $primary-color;
  color: $primary-color;

  &:focus {
    border: 1px solid $primary-color;
  }

  &:hover {
    color: $primary-color;
  }
}

.primary-btn {
  background: $primary-color;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #BDBDBD;
  border-radius: 40px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #BDBDBD;
}

.pointer {
  cursor: pointer;
}