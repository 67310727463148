@import '../../../theme.scss';

.tutorstartarea-component {
    margin-top: -70px;
    color: white;
    h1, h2 {
        margin: 0;
        text-align: center;
    }
    p {
        margin: 0;
    }
    a {
        color: #23A4EF;
    }
    .input-box {
        border: none;
        margin-bottom: 30px;
        backdrop-filter: blur(8px);
        .inputbox-container {
            border: none;
            background: rgba(255, 255, 255, 0.28);
            .search-text {
                color: white;
            }
            ::placeholder {
                color: #A6AFC0;
            }
        }
        .inputbox-bg {
            display: none;
        }
    }
    .inner-area {
        &.content-part {
            display: flex;
            align-items: flex-start;
            p {
                font-size: 15px;
                line-height: 23px;
            }
        }
    }
    .check-box {
        width: 28px;
        height: 28px;
        background: rgba(255, 255, 255, 0.28);
        border: none;
        border-radius: 0;
        backdrop-filter: blur(8px);
        svg {
            fill: white;
        }
    }
    .start-btn2 {
        background-color: $primary-color;
        color: white;
        font-weight: bold;
        font-size: 20px;
        cursor: pointer;
    }
}

@media screen and (min-width: 992px) {
    .tutorstartarea-component {
        background: no-repeat url('../../../Assets/Tutor/bg.png');
        background-position: center;
        background-size: 100% 100%;
        height: 730px;
        padding-top: 181px;
        h1 {
            font-size: 45px;
            margin-bottom: 30px;
        }
        h2 {
            font-size: 20px;
            margin-bottom: 39px;
        }
        .inner-area {
            max-width: 706px;
            width: 100%;
            margin: 0 auto;
        }
        .content-part {
            padding: 0 15px;
            margin-bottom: 30px;
            p {
                margin-left: 14px;
            }
        }
        .start-btn2 {
            max-width: 676px;
            height: 63px;
        }
    }
}

@media screen and (max-width: 991px) {
    .tutorstartarea-component {
        background: no-repeat url('../../../Assets/Tutor/bg.png');
        background-position: center;
        background-size: cover;
        height: 815px;
        padding-top: 121px;
        h1 {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 14px;
        }
        h2 {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 37px;
        }
        .content-part {
            margin-top: 10px;
            margin-bottom: 23px;
            p {
                margin-left: 6px;
            }
        }
        .start-btn2 {
            height: 50px;
        }
    }
}