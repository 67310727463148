@import '../../../theme.scss';

.fc-question-header-component {
  padding: 40px 0;
  margin-top: 40px;
  color: $white900;

  h1 {
    font-size: 44px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
    color: $dark-color;
  }

  p {
    font-size: 16px;
    color: $white700;
    line-height: 25.6px;
    white-space: pre-line;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px;
    color: $dark-color
  }

  .question-header-container {
    background: no-repeat url('../../../Assets/Flashcard/header-banner.png');
    background-position: center;
    height: 299px;
    
    .row {
      height: 100%;
    }

    .left-content {
      padding: 10px 45px 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .opacity {
        opacity: 0.6;
      }
    }

    .right-content {
      padding-right: 0;

      .header-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .fc-question-header-component {
    .question-header-container {

        h1 {
          font-size: 24px;
          line-height: 50px;
        }

       .left-content {
        padding: 15px;
       }
      }
  }
}