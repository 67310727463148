@import '../../../theme.scss';

.pt-summary-wrapper {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .pt-summary-wrapper {
    margin-left: 50px;
    height: 300px;
    width: 80%;
    margin-top: 0 !important;


    .banner-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .banner {
        width: 100%;
        height: 100%;
      }

      &::before {
          position: absolute;
          content: "";
          width: 20px;
          height: 100%;
          background-color: $green700;
          right: 100%;
          top: -20px
        }
      
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 20px;
          bottom: 100%;
          left: 0;
          background-color: $green700;
        }
      }
    }
}