.fc-question-section {

    .row {
        margin-bottom: 4rem;

        .right-section {
            padding-top: 2rem;
        }

        .question-container {
            width: 100%;
            min-height: 30rem;
            background-color: #F9FAFB;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 42px 50px;
        }
    }
}

@media screen and (max-width: 991px) {
    .fc-question-section {

        .question-container {
            padding: 1rem 0 !important; 
        }
    }
}