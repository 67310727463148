@import '../../../../theme.scss';

.popular-question-wrapper {
  position: relative;
  width: 100%;

  .dotted-bg {
      position: absolute;
      background: no-repeat url('../../../../Assets/Common/bg-dot-3.png');
      background-position: center right;
      width: 100%;
      height: 100%;
      right: -11px;
      top: 34px;
      z-index: -1;
      background-size: contain;
  }

  .popular-question-item-component {
      margin: 20px 0;
      padding: 20px;
      font-size: 16px;
      cursor: pointer;
      background-color: white;
  
      .question-container {
        .question-title {
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
  
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
}

@media screen and (max-width: 992px) {
  .popular-question-wrapper {
    .dotted-bg {
      display: none;
    }
  }
}