@import '../../../theme.scss';

.resource-list-component {

    .component-wrapper {
        padding-bottom: 4rem;
        padding-top: 36px;
    }

    .load-more-btn {
        margin-top: 30px;
        margin-bottom: 20px;
        display: table;
        margin-left: auto;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
    }
}

@media screen and (max-width: 992px) {
    h2 {
        font-size: 28px;
        line-height: 50px;
        text-align: center;
    }

    .sub-description {
        text-align: center;
    }
}

h2 {
    font-size: 32px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
}

.sub-description {
    font-size: 16px;
}

.download-file-modal {

    .modal-content {
        padding: 1rem 10px;
        border: none;
    }

    .form-control {
        border-radius: 0;
    }

    .form-control:focus, .form-control:active {
        box-shadow: none;
        outline: none;
    }

    .btn-3 {
        font-size: 15px;
        margin-top: 20px;
        padding: 10px 20px;
        margin-top: 20px;
    }
}