@import '../../../theme.scss';

.video-detail-page {

  .video-submit {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    background-color: #00b9a1;
    color: #FFF;
    padding: 20px;
    height: 100%;

    .photo {
      border-radius: 50%;
      align-self: center;
    }

    .submit-container {
      .submit-note {
        font-size: 20px;
      }

      .email-input {
        border: none;
        width: 100%;
        height: 40px;
        padding: 0px 15px;
        border-radius: 5px;
      }

      .email-input-error {
        border: solid 2px red;
      }
    }

    .submit-btn {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      font-size: 13px;
      background-color: #21323b;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      cursor: pointer;
    }

  }

  .video-title {
    font-size: 22px;
    padding: 20px 0;
  }

  .video-share-subscribe {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .video-share {
      display: flex;
      align-items: center;

      .share-description {
        font-size: 17px;
        margin-right: 20px;
      }

      .share-btn {
        margin: 0 10px;
      }
    }

    .subscribe-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 50px;
      background-color: #007bff;
      color: #FFF;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .video-description {
    padding: 30px 0;
  }

  .related-videos-container {
    .related-video-head-title {
      font-size: 20px;
      font-weight: 500;
    }

    .related-video-list {
      padding: 10px 0px;

      .video-image {
        width: 100%;
      }

      .related-video-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .play-btn-bg {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #23A4EF;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;

        .play-icon {
          font-size: 30px;
          color: #FFF;
        }

        video {
          object-fit: fill;
          position: relative;
        }
      }
    }
  }
}


@media screen and (min-width: 992px) {

  .video-detail-page {
    padding: 50px 150px;

    .video-player-area {
      // min-height: 560px;
      background-color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }

    h2 {
      font-size: 28px;
    }

    .video-submit {
      .subscribing-img {
        width: 120px;
        height: 120px;
        object-fit: contain;
        border-radius: 50%;
        align-self: center;
        border: solid 1px #FFF;
      }
    }
  }
}

@media screen and (max-width: 991px) {

  .video-detail-page {
    padding: 50px 15px;

    .player-area {
      height: 300px;
    }

    h2 {
      font-size: 20px;
    }

    .video-submit {
      .subscribing-img {
        width: 40%;
        margin: 30px 0px;
      }

      .submit-container {
        .submit-note {
          font-size: 15px;
        }
      }
    }

    .related-videos-container {
      .related-video-head-title {
        margin-top: 30px;
      }
    }
  }
}