@import '../../../theme.scss';

.subject-lists-component {
  padding: 40px 0;
  color: $blue600;

  h2 {
    font-size: 32px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
  }

  span {
    font-size: 16px;
    text-align: center;
    white-space: pre-line;
  }

  .sub-description {
    font-size: 16px;
  }

  .home-start-container {
    .left-content {
      .subject-list {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(4, 1fr);
      }

      .load-more-btn {
        margin-top: 30px;
        margin-bottom: 20px;
        display: table;
        margin-left: auto;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .subject-lists-component {
    padding: 30px 0;

    h2 {
      font-size: 28px;
      line-height: 50px;
      text-align: center;
    }

    .sub-description {
      text-align: center;
    }

    .home-start-container {
      .left-content {
        .subject-list {
          display: grid;
          gap: 0.5rem;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}