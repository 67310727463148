@import '../../../theme.scss';

.fc-list-section {
    margin-bottom: 3rem;

    .fc-wrapper {
        padding: 1rem 0;

        .right-section {
            padding-top: 2rem;
        }

        .topic-item-wrapper {
            position: relative;
            width: 100%;
            margin: 0;

            .topic-item-card {
                margin: 12px 0;
                padding: 27px 20px;
                font-size: 16px;
                cursor: pointer;
                background-color: white;
                box-shadow: 0px 14px 30px rgba(3, 45, 30, 0.15);
                transition: ease-in 0.2s;

                &:hover {
                    transform: scale(1.05);
                    transition: ease-in 0.3s;
                    box-shadow: 0px 14px 30px rgba(3, 45, 30, 0.21);
                }
               
                .item-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-overflow: ellipsis;

                    .title {
                        font-weight: 700;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }

            .dotted-bg {
                position: absolute;
                background: no-repeat url('../../../Assets/PracticeQuestions/dotted-bg.png');
                background-position: center right;
                width: 100%;
                height: 100%;
                right: -11px;
                top: 19px;
                z-index: -1;
                background-size: contain;
            }
        }
    }

    .load-more-btn {
        margin-top: 40px;
        margin-bottom: 20px;
        display: table;
        margin-left: auto;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
    }
}

@media screen and (max-width: 992px) {
    .fc-list-section {
        .fc-wrapper {
            padding: 0 !important;

            .dotted-bg {
                display: none;
            }
        }
    }
}