@import '../../../../theme.scss';

.fc-bg-wrapper {
  position: relative;

  .dotted-bg {
    position: absolute;
    background: no-repeat url('../../../../Assets/Common/Subject-bg.png');
    background-position: center right;
    width: 100%;
    height: 100%;
    right: -10px;
    top: 50px;
    z-index: -1;
  }

  .fc-subject-item-component {
      flex-direction: column;
      padding: 20px;
      cursor: pointer;
      background-color: white;
      height: 142px;
      transition: ease-in .3s;

      &:hover {
        transform: scale(1.05);
        transition: ease-in 0.3s;
      }
  
      .subject-name {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        margin-top: 0.65em;
      }
    }
}