@import '../../theme.scss';

.signup-page {
    display: flex;
    font-family: Maax;
    color: $secondary-text;
    position: relative;
    overflow-x: hidden;
    .spinner {
        background-color: red;
    }
    h1 {
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 0;
    }
    .referal-code-title {
        font-size: 15px;
        font-weight: 500;
    }

    .owl-theme {
        .owl-nav {
            position: absolute;
            display: flex;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            left: -30px;
            right: -30px;
            pointer-events: none;
            .owl-prev, .owl-next {
                pointer-events: auto;
                outline: none;
                &:hover {
                    background: none;
                }
            }
        }
        .owl-stage-outer {
            padding-bottom: 10px;
            .owl-stage {
                display: flex;
            }
        }
    }   

    .center-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .normal-item {
        border: 2px solid #E7EBEE;
        background: white;
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &.active {
            color: white;
            background: $secondary-text;
        }
    }
    
    .work-area {
        background: white;

        .work-header {
            height: 55px;
            left: 493px;
            right: 0;
            position: fixed;
            z-index: 30;
            background: transparent;
            top: 0;
            .work-container {
                height: 100%;
                padding-top: 26px;
                padding-bottom: 5px;
                background: white;
            }

            .header-row {
                height: 100%;
                .header-col {
                    height: 100%;
                    .col-name {
                        height: 100%;
                        font-size: 11px;
                        color: #E0E4E7;
                        border-bottom: 2px solid #F3F3F3;
                        font-weight: bold;

                        &.active {
                            color: $primary-text;
                            border-bottom-color: $primary-text;
                        }

                        &.passed {
                            text-decoration: line-through;
                        }
                    }   
                }
            }
        }

        .work-container {
            display: flex;
            flex-direction: column;
            .work-content {
                #section-10-3 {
                    .free-lesson {                           
                        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                        background: white;
                        z-index: 2;
                        position: relative;
                        background: white;
                    }
                    .additional-bg {
                        left: 32px;
                        right: 3px;
                        top: 379px;
                        bottom: -14px;
                    }
                    .additional-bg2 {
                        left: 32px;
                        right: 3px;
                        top: 481px;
                        bottom: -14px;
                    }
                }
                #section-1-1, #section-1-4, #section-6-1, #section-9-1 {
                    .claim-container {
                        padding: 0 15px;
                        .claim-content {
                            background-color: yellow;
                        }
                    }
                    .grade-level {
                        width: 100%;
                        font-weight: bold;
                        cursor: pointer;
                        text-align: center;

                        img {
                            margin-right: 15px;
                        }
                    }
                    .done-free-trial {
                        font-size: 17px;
                        font-weight: 500;
                        background-color: yellow;
                        padding: 10px;
                        margin-bottom: 20px;
                    }
                }
                #section-1-3 {
                    textarea {
                        resize: none;
                        width: 100%;
                        border: 2px solid #E7EBEE;
                    }
                    
                }
                #section-2-1 {
                    .tutor-date {
                        padding: 23px 0;
                        font-weight: bold;
                        cursor: pointer;
                        .month {
                            font-size: 20px;
                        }
                        .day {
                            margin-top: 12px;
                            font-size: 36px;
                        }
                        .weekday {
                            font-weight: normal;
                            margin-top: 6px;
                            color: #7B88A0;
                        }
                        &.active {
                            .weekday {
                                color: white;
                            }
                        }
                    }
                    .tutor-date-bg {
                        top: initial;
                        height: 63px;
                    }
                }
                #section-2-2 {
                    .tutor-time {
                        padding-top: 15px;
                        padding-bottom: 12px;
                        font-weight: bold;
                        text-align: center;
                    }
                }
                #section-2-3 {
                    .schedule-col {
                        display: flex;
                        align-items: center;
                    }
                    p {
                        margin-left: 19px;
                        font-size: 18px;
                    }
                }
                #section-4-1, #section-7-1, #section-7-2 {
                    .profile-col {
                        margin-bottom: 31px;
                        font-size: 30px;
                        font-weight: bold;
                        .card-element {
                            width: 569px;
                        }
                        .payment-card-header {
                            margin-bottom: 30px;
                        }                        
                    }
                    .already-login {
                        font-size: 18px;
                        font-weight: 500;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                    .inner-area {
                        display: flex;
                        margin: 30px 15px;
                        p {
                            font-size: 14px;
                            margin-left: 20px;                            
                        }
                    }
                }
                #section-9-1 {
                    .custom-input {
                        width: 100%;
                        position: relative;
                    
                        .inputbox-container {
                            position: relative;
                            font-family: Maax;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            border: 2px solid #E7EBEE;
                            background: white;
                            z-index: 2;
                            overflow: hidden;
                        
                            .search-text {
                                flex: 1;
                                height: 100%;
                                border: none;
                                outline: none;
                                padding-left: 0;
                                background: white;
                                align-items: center;
                                display: flex;
                                color: #A6AFC0;
                                overflow: hidden;
                            }

                            .predend-text {
                                cursor: pointer;
                                font-weight: bold;
                                color: $primary-text;
                                font-size: 13px;
                                padding-left: 12px;
                            }
                        }
                    
                        .inputbox-bg {
                            bottom: initial;
                        }
                    }
                }
                #section-10-3 {
                    
                    .coupon-area {
                        margin-left: 15px;
                        // height: 200px;
                        .coupon-row-item {
                            display: flex;
                            align-items: center;
                            margin-top: 20px;
                            .coupon-txt {
                                color: $primary-text;
                                font-weight: 500;
                            }
                            .coupon-toggle {
                                margin-left: 34px;
                            }
                            .coupon-ok-btn {
                                margin-left: 20px;
                                margin-top: 0;
                            }
                            
                        }
                    }
                }
                .ok-btn {
                    cursor: pointer;
                    background: $primary-text;
                    color: white;
                    font-size: 20px;
                    width: 100px;
                    height: 60px;
                    margin-top: 30px;
                }
                .unActive-button {
                    cursor: pointer;
                    background: gray;
                    color: white;
                    font-size: 20px;
                    width: 100px;
                    height: 60px;
                    margin-top: 30px;
                }
            }
        }

        .submit-area {
            border-top: 1px solid #D8D8D8;

            .btn-area {
                .back-btn {
                    color: #C0C5C9;
                    border: 2px solid #C0C5C9;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .back-icon {
                        border-right: 2px solid #C0C5C9;
                        height: 100%;
                    }
                    .back-text {
                        height: 100%;
                        font-weight: bold;
                    }
                    &.back-btn2 {
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    &.back-btn3 {
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .plan-continue-btn {
                display: flex;
                justify-content: flex-end;
            }

            &.custom-submit {
                border-top: none;
            }
        }
    }

    .progress-explain {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        color: white;
    }
}

@media screen and (min-width: 992px) {
    .work-content {
        animation: contentShow 0.3s ease-out;
    }

    .video-react {
        height: 450px;
    }
    
    .signup-page {
        margin-top: -70px;
        position: relative;
        background: no-repeat url('../../Assets/Home/index-bg.jpg');
        background-position: 0 0;
        background-size: 100% 100vh;
        min-height: 100vh;

        .work-area {
            animation: contentAppear 0.3s ease-out;
            width: calc(100% - 493px);
            margin-left: auto;
            max-height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;

            .work-container {
                max-width: 900px;

                .work-content {
                    margin-top: 55px;
                    flex: 1;

                    h1 {
                        font-size: 36px;
                        margin-bottom: 18px;
                    }

                    p {
                        font-size: 15;
                    }
                    #section-10-2 {
                        margin-top: 30px;
                        .note {
                            padding: 15px;
                            background: #F9FAFB;
                            margin-bottom: 50px;
                            .totoal-price {
                                font-weight: bold;
                                color: #203662;
                            }
                        }
                    }

                    #section-10-3 {
                        margin-top: 120px;
                        .free-lesson {
                            padding: 30px;
                            p {
                                color: #7B88A0;
                            }
                            .lesson-title {
                                margin: 25px 0 25px;
                            }
                            .free-lesson-header {
                                border-bottom: #E7EBEE solid 2px;
                            }
                            .free-lesson-body {
                                .body-item {
                                    display: flex; 
                                    margin-top: 30px;                                   
                                }
                                .explain {
                                    margin-left: 15px;
                                }
                            }

                        }
                    }

                    #section-1-2 {
                        margin-top: 137px;
                    }

                    #section-1-1, #section-1-4, #section-6-1, #section-9-1 {
                        margin-top: 30px;
                        .claim-container {
                            font-size: 28px;
                            font-weight: bold;
                            .claim-content {
                                margin-bottom: 15px;
                            }
                        }                 
                        .price-note {
                            margin-right: 10px;
                            padding: 15px;
                            background: #F9FAFB;
                            margin-bottom: 50px;
                            font-size: 18px;
                            color: #203662;
                            border: solid 2px #203662;
                        }
                        .slider-bar {
                            margin-top: 50px;
                            color: #203662;
                            .slider-des-body {
                                font-size: 13px;
                                margin-bottom: 20px;
                            }
                        }
                        p {
                            font-weight: bold;
                            font-size: 20px;
                            color: #203662;
                            margin-bottom: 20px;
                            
                        }
                        .grade-level-col {
                            margin-bottom: 44px;
                        }

                        .grade-level {
                            height: 65px;
                            border: 2px solid #E7EBEE;
                            font-size: 20px;
                            background: white;
                            z-index: 2;
                            position: relative;

                            &.active {
                                color: white;
                                background: $secondary-text;
                            }
                        }

                        .grade-level-bg {
                            left: 27px;
                            top: 14px;
                            bottom: -12px;
                            right: 3px;
                        }
                    }

                    #section-1-2 {
                        margin-top: 137px;
                    }

                    #section-1-3 {
                        margin-top: 234px;
                        textarea {
                            height: 135px;
                            padding: 17px 24px;
                            z-index: 2;
                            position: relative;
                        }                        
                        .additional-bg {
                            left: 27px;
                            right: 3px;
                            top: 84px;
                            bottom: -5px;
                        }
                    }

                    #section-1-4 {
                        margin-top: 232px;
                    }

                    #section-2-1 {
                        margin-top: 50px;
                    }

                    #section-2-2 {
                        margin-top: 111px;
                        .tutor-time-col {
                            margin-bottom: 33px;

                            .tutor-time {
                                font-size: 20px;
                                text-align: center;
                                .day-of-time {
                                    margin-bottom: 15px;
                                }
                            }

                            .tutor-time-bg {
                                left: 27px;
                                top: initial;
                                bottom: -12px;
                                right: 3px;
                                height: 63px;
                            }
                        }
                    }

                    #section-2-3 {
                        margin-top: 103px;
                    }

                    #section-3-1, #section-5-1 {
                        margin-top: 103px;

                        .custom-feedback {
                            min-height: 366px;
                        }

                        .remarks {
                            color: #7B88A0;
                            font-size: 15px;
                            line-height: 22px;
                            margin-top: 24px;
                        }
                    }

                    #section-4-1, #section-7-1, #section-7-2, #section-2-1 {
                        margin-top: 80px;
                        .profile-col {
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .card-element {
                                width: 569px;
                            }
                        }
                        .already-login {
                            font-size: 18px;
                            font-weight: 500;
                            text-decoration-line: underline;
                            cursor: pointer;
                        }

                        .step-description{
                            background: yellow;
                            font-size: 17px;
                            padding: 10px;
                            font-weight: 500;
                            margin-top: -60px;
                            margin-bottom: 60px;
                        }
                    }


                    #section-6-1 {
                        margin-top: 300px;
                    }

                    #section-7-1, #section-7-3 {
                        // margin-top: 148px;
                    }

                    #section-7-2 {
                        margin-top: 138px;
                    }

                    #section-8-1 {
                        margin-top: 92px;
                        .video-area {
                            height: 400px;
                            background-color: white;
                        }
                    }

                    #section-9-1 {
                        .inputbox-container {
                            height: 65px;
                            padding-left: 22px;
                    
                            .search-text {
                                font-size: 20px;
                                padding-left: 16px;
                            }

                            .predend-text {
                                padding-right: 22px;
                            }
                        }
                    
                        .inputbox-bg {
                            height: 65px;
                            left: 12px;
                            top: 14px;
                            right: -12px;
                        }
                    }
                }
            }

            .submit-area {
                margin-top: 130px;
                .submit-container {
                    max-width: 900px;
                    padding-top: 142px;

                    .btn-area {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .back-btn {
                            height: 65px;
                            width: 143px;
                            .back-icon {
                                width: 65px;
                            }
                            .back-text {
                                flex: 1;
                                font-size: 20px;
                            }
                            &.back-btn2 {
                                width: 96px;
                            }
                            &.back-btn3 {
                                width: 96px;
                                display: flex;
                                justify-content: flex-end;
                                margin-left: auto;
                            }
                        }

                        .start-btn {
                            width: 338px;
                            background: red;
                        }
                    }
                }
                &.custom-submit {
                    margin-top: 0;
                    .submit-container {
                        padding-top: 54px;
                        padding-bottom: 15px !important;
                    }
                }
                &.final-submit {
                    margin-top: 59px;
                    .submit-container {
                        padding-top: 78px;
                        padding-bottom: 0 !important;
                    }
                }
            }
        }

        .progress-explain {
            position: fixed;
            height: 100vh;
            max-height: 100vh;
            top: 0;
            width: 493px;
            padding: 0 41px 0 65px;
            overflow-y: auto;

            .custom-bg {
                width: 338px;
                height: 260px;
                left: 109px;
                bottom: calc(50% - 130px);
                background: url('../../Assets/Common/bg-dot-2.png');
            }

            h1 {
                font-size: 50px;
                line-height: 56px;
                margin-bottom: 26px;
            }

            p {
                font-size: 18px;
                line-height: 30px;
                padding-right: 80px;
            }

            .explain-content {
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 20;
                max-height: 100%;
                #section-explain-6 {
                    // max-height: calc(100% - 10px);
                    width: calc(100% + 15px);
                    padding-right: 15px;
                    overflow-y: scroll;
                    margin-top: auto;
                    .provide-contents {
                        padding-top: 19px;
                        display: flex;
                        flex-direction: column;
                        .provide {
                            display: flex;
                            margin-bottom: 29px;
                            font-size: 18px;
                            line-height: 30px;
                            img {
                                width: 28px;
                                height: 28px;
                                margin-right: 9px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .next-contents {
                        margin-top: 66px;
                        .question-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .explain {
                                font-size: 18px;
                                // font-weight: 500;
                                width: 328px;
                                line-height: 26px;
                                margin-bottom: 18px;
                                cursor: pointer;     
                                                
                            }
                            .icon {
                                cursor: pointer;
                                fill: white;
                            }
                            .icon-close {
                                transform: rotate(0deg);
                            }
                            .has-icon-close {
                                transform: rotate(90deg);
                                // fill: white;
                            }    
                            
                        }
                        .content-list {
                            .accordion {
                                color: white;
                                border-top: 1px solid #334650;
                                padding: 13px 0;
                                .title-area {
                                    .accordion-title {
                                        font-weight: normal;
                                        font-size: 18px;
                                        line-height: 30px;
                                        width: 328px;
                                    }
                                }
                                &:last-child {
                                    border-bottom: 1px solid #334650;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .signup-page {
        min-height: calc(100vh - 70px);

        .video-react {
            height: 250px;
        }
        .progress-explain {
            display: none;
        }

        .work-area {
            width: 100%;

            .work-container {
                .work-content {
                    margin-top: 55px;
                    flex: 1;

                    h1 {
                        font-size: 24px;
                        line-height: 38px;
                        margin-bottom: 18px;
                    }

                    h2 {
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 38px;
                    }

                    p {
                        font-size: 15;                        
                    }

                    #section-10-2 {
                        margin-top: 30px;
                        .note {
                            margin-left: 10px;
                            margin-right: 10px;
                            padding: 15px;
                            background: #F9FAFB;
                            margin-bottom: 50px;
                            .totoal-price {
                                font-weight: bold;
                                color: #203662;
                            }
                        }
                    }

                    #section-10-3 {
                        margin-top: 60px;
                        .free-lesson {                            
                            padding: 30px;
                            p {
                                color: #7B88A0;
                            }
                            .lesson-title {
                                margin: 25px 0 25px;
                            }
                            .free-lesson-header {
                                border-bottom: #E7EBEE solid 2px;
                            }
                            .free-lesson-body {
                                .body-item {
                                    display: flex; 
                                    margin-top: 30px;                                   
                                }
                                .explain {
                                    margin-left: 15px;
                                }
                            }

                        }
                    }

                    #section-1-1, #section-1-4, #section-6-1, #section-9-1 {
                        margin-top: 40px;
                        .claim-container {
                            font-size: 23px;
                            font-weight: bold;
                            .claim-content {
                                text-align: center;
                                margin-bottom: 15px;
                            }
                        } 
                        p {
                            font-weight: bold;
                            font-size: 24px;
                            color: #203662;
                            margin-bottom: 20px;
                            
                        }
                        .grade-level-col {
                            margin-bottom: 30px;
                        }

                        .grade-level {
                            height: 50px;
                            border: 2px solid #E7EBEE;
                            font-size: 16px;
                            background: white;
                            z-index: 2;
                            position: relative;

                            &.active {
                                color: white;
                                background: $secondary-text;
                            }
                        }

                        .grade-level-bg {
                            left: 23px;
                            top: 8px;
                            bottom: -8px;
                            right: 7px;
                        }
                    }

                    #section-1-2 {
                        margin-top: 55px;
                    }

                    #section-1-3 {
                        margin-top: 86px;
                        textarea {
                            height: 135px;
                            padding: 12px 16px;
                            z-index: 2;
                            position: relative;
                        }
                        .additional-bg {
                            left: 27px;
                            right: 3px;
                            top: 84px;
                            bottom: -5px;
                        }
                    }

                    #section-2-1 {
                        margin-top: 35px;
                    }

                    #section-2-2 {
                        margin-top: 100px;
                        .tutor-time-col {
                            margin-bottom: 28px;

                            .tutor-time-bg {
                                left: 27px;
                                top: initial;
                                bottom: -12px;
                                right: 3px;
                                height: 50px;
                            }
                        }
                    }

                    #section-2-3 {
                        margin-top: 53px;
                    }

                    #section-1-4 {
                        margin-top: 102px;
                    }

                    #section-3-1, #section-5-1 {
                        margin-top: 41px;

                        .remarks {
                            color: #7B88A0;
                            font-size: 15px;
                            line-height: 23px;
                            margin-top: 28px;
                        }
                    }

                    #section-4-1, #section-7-1, #section-7-2, #section-2-1 {
                        margin-top: 25px;

                        .profile-col {
                            display: flex;
                            align-items: center;
                            .card-element {
                                max-width: 360px;
                            }
                            .initial-name {
                                width: 75px;
                                height: 75px;
                                background-color: #3ABDAA;
                                font-size: 20px;
                                font-weight: bold;
                                border-radius: 50%;
                                color: white;
                                margin-right: 30px;
                                overflow: hidden;
                                text-transform: uppercase;
                            }
                            label {
                                flex: 1;
                                margin: 0;
                                .upload-btn {
                                    width: 100%;
                                    height: 40px;
                                    border: 2px solid $secondary-text;
                                    font-size: 15px;
                                    font-weight: bold;
                                }
                            }
                        }

                        .already-login {
                            font-size: 18px;
                            font-weight: 500;
                            text-decoration-line: underline;
                            cursor: pointer;
                        }

                        .step-description {
                            background: yellow;
                            font-size: 17px;
                            padding: 10px;
                            font-weight: 500;
                            margin-bottom: 20px;
                        }
                    }

                    #section-6-1 {
                        margin-top: 36px;
                    }

                    #section-7-1, #section-7-3 {
                        margin-top: 36px;
                    }

                    #section-7-2 {
                        margin-top: 56px;
                    }

                    #section-8-1 {
                        margin-top: 39px;
                        .video-area {
                            height: 195px;
                            background-color: white;
                        }
                    }

                    #section-9-1 {
                        .inputbox-container {
                            height: 50px;
                            padding-left: 17px;
                    
                            .search-text {
                                font-size: 16px;
                                padding-left: 14px;
                            }

                            .predend-text {
                                padding-right: 17px;
                            }
                        }
                        
                        .inputbox-bg {
                            height: 50px;
                            left: 8px;
                            top: 8px;
                            right: -8px;
                        }
                    }
                }
            }

            .submit-area {
                margin-top: 60px;
                .submit-container {
                    padding-top: 60px;
                    padding-bottom: 0px;
                    .btn-area {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .back-btn {
                            height: 50px;
                            width: 135px;
                            .back-icon {
                                width: 50px;
                            }
                            .back-text {
                                flex: 1;
                                font-size: 16px;
                            }
                            &.back-btn2 {
                                width: 90px;
                            }
                            &.back-btn3 {
                                width: 165px;
                                margin-left: auto;
                            }
                        }

                        .start-btn {
                            width: 193px;
                            font-size: 16px;
                            height: 50px;
                            background: red;
                        }
                    }
                }

                &.custom-submit {
                    margin-top: 0;
                    .submit-container {
                        padding-top: 53px;
                        padding-bottom: 15px !important;
                    }
                }

                &.final-submit {
                    margin-top: 60px;
                    .submit-container {
                        padding-top: 46px;
                        padding-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}

@keyframes contentAppear {
    0% { 
        opacity: 0;
        transform: translateX(100%);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}

#section-explain-1, #section-explain-2, #section-explain-3, #section-explain-4, #section-explain-5, #section-explain-6, #section-explain-7, #section-explain-8, #section-explain-9 {
    animation: contentShow 0.3s ease-out;
}
