@import '../../../theme.scss';

.question-header-component {
  padding: 40px 0;
  margin-top: 40px;
  color: $white900;

  h1 {
    font-size: 44px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
    padding: 5px 0 10px;
  }

  p {
    font-size: 16px;
    color: $white700;
    line-height: 25.6px;
    white-space: pre-line;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px;
  }

  .question-header-container {
    background: no-repeat url('../../../Assets/CommonQuestions/subject-header-bg.png');
    background-position: center;

    .left-content {
      padding: 10px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .opacity {
        opacity: 0.8;
      }
    }

    .right-content {
      padding-right: 0;

      .header-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .question-header-component {
    padding: 30px 0;

    h1 {
      font-size: 38px;
      text-align: center;
      line-height: 40px;
    }

    p {
      text-align: center;
    }

    span {
      text-align: center;
    }

    .question-header-container {
      .left-content {
        padding: 15px;
      }
    }
  }
}