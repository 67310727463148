@import '../../../theme.scss';

.question-answer-flow-component {
  .frame-icon {
    position: absolute;
    right: 40px;
    top: 40px;
  }

  .question-content {
    padding: 30px 0 50px;
  }

  .btn-content {
    margin-top: 36px;

    .back-btn {
      color: $blue800;
      background-color: $blue800_10;
    }

    .skip-btn {
      margin-right: 10px;
      background-color: #F9FAFB;
      color: $blue800;
    }

    .step-num {
      font-size: 12px;
      opacity: 0.6;

      strong {
        opacity: 1;
      }
    }
  }
}