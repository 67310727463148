@import '../../../theme.scss';

.tv-startarea-component {
  font-family: Maax;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;

  h4 {
    margin-top: 30px;
  }

  .tv-title {
    margin-top: 150px;
  }

  .subscribe-btn {
    cursor: pointer;
  }

  .logo-img {
    width: 220px;
  }
}

@media screen and (min-width: 992px) {
  .tv-startarea-component {
    // background: no-repeat url('../../../Assets/Tv/header-bg.jpg');
    background-position: center;
    background-size: 100% 100%;
    height: 685px;

    h1 {
      font-size: 44px;
      font-weight: 700;
    }

    h4 {
      font-size: 20px;
    }

    .tv-start-header {
      padding-top: 70px;
      display: flex;
      justify-content: flex-end;
    }

    .tv-title {
      margin-top: 100px;
      padding: 0px;

      .subscribe-btn {
        width: 170px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-bg;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .tv-startarea-component {
    // background: no-repeat url('../../../Assets/Tv/header-bg.jpg');
    background-position: center;
    // background-size: 100% 100%;
    height: 566px;

    h1 {
      font-size: 30px;
      font-weight: 700;
    }

    h4 {
      font-size: 18px;
    }
    
    .logo-img {
      width: 170px;
    }

    .tv-start-header {
      padding-top: 30px;
    }

    .tv-title {
      margin-top: 50px;
      padding: 0px;

      .subscribe-btn {
        width: 170px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-bg;
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
}