@import '../../../theme.scss';

.startarea-search-component {
    font-family: Maax;
    margin-top: -70px;
    position: relative;
    display: flex;
    flex-direction: column;

    .startarea-search-content {
        .startarea-search-container {
            .main-row {
                .left-area {
                    .search-area {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 20px 0;
                        background-color: $primary-color;
                        border-radius: 10px;
                        margin: 20px 0px;

                        .item-area {
                            height: 40px;
                            padding: 0 10px;

                            .input-area {
                                font-size: 13px;
                                height: 100%;
                                background-color: #FFF;
                                border-radius: 5px;
                            }

                            .find-button{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #FFF;
                                font-size: 18px;
                                height: 100%;
                                background-color: $green-color;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .logo-area {
                justify-content: space-between;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .startarea-search-component {
        background: no-repeat url('../../../Assets/Home/index-bg1.jpg');
        background-position: center;
        background-size: 100% 100%;

        h1 {
            font-size: 34px;
            font-weight: bold;
        }

        p{
            font-size: 13px;
        }

        .startarea-search-content {
            padding: 70px 0 30px;
            display: flex;
            flex-direction: column;

            .startarea-search-container {
                display: flex;
                flex-direction: column;
                padding-top: 50px;

                .main-row {
                    display: flex;
                    .left-area {
                        padding: 130px 0;
                    }
                    .right-area {
                        display: flex;
                        img {
                            width: 100%;
                        }
                    }
                }                
            }

            .logo-area {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .startarea-search-component {
        background: no-repeat url('../../../Assets/Home/index-bg1.jpg');
        background-position: -430px 0;
        background-size: cover;
        
        h1 {
            font-size: 28px;
            font-weight: bold;
        }

        p {
            font-size: 13px;
        }

        .startarea-search-content {
            padding-top: 70px;
            .startarea-search-container {
                padding: 70px 0 30px;

                .main-row {
                    margin: 0;
                    .left-area {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .search-area {
                            .item-area {
                                margin: 5px 0;
                            }
                        }                        
                    }
                    .right-area {
                        margin-top: 30px;
                        img {
                            width: 100%;
                        }
                    }
                }                
            }

            .logo-area {
                margin-bottom: 25px;
                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    height: 20px;
                }
            }
        }
    }
}