@import '../../../../theme.scss';

.bg-wrapper {
  position: relative;

  .dotted-bg {
    position: absolute;
    background: no-repeat url('../../../../Assets/Common/Subject-bg.png');
    background-position: center right;
    width: 100%;
    height: 100%;
    right: -13px;
    top: 40px;
    z-index: -1;
  }

  .subject-item-component {
      flex-direction: column;
      padding: 20px;
      cursor: pointer;
      background-color: white;
  
      .subject-name {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }
    }
}