@import '../../../theme.scss';

.cq-footer-component {
  background-color: $blue900;
  color: $white900;

  .header {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-2 {
      padding: 11px, 24px, 9px, 24px !important;
    }
  }

  .body {
    padding: 30px 0;
    border-top: solid 1px $white100;
    border-bottom: solid 1px $white100;

    .tag-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .tag-body {
      display: flex;
      flex-direction: column;

      .tag {
        font-size: 14px;
        color: $white900;
        opacity: 0.6;
        padding: 5px 0;

        &:hover {
          color: $blue800 !important;
        }
      }
    }
  }

  .footer {
    padding: 20px 0;

    .stores {
      display: flex;
      align-items: center;

      .app-store {
        margin-left: 15px;
      }
    }

    &__link {
      font-size: 12px;
      margin-right: 20px;
      color: $white900;
      opacity: 0.6;
    }

    .social {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .cq-footer-component {
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 991px) {
  .cq-footer-component {
    .logo {
      width: 115px;
      height: auto;
    }

    .col-lg-3,
    .col-lg-4 {
      width: 50%;
    }

    .col-lg-3,
    .col-lg-4,
    .col-lg-5 {
      padding: 15px;
      border-bottom: solid 1px $white100;
    }

    .header {
      padding: 30px 0;
      flex-direction: column;

      .btn-3 {
        margin-top: 20px;
        width: 100%;
      }
    }

    .body {
      padding: 20px 0;
      border-bottom: none;
    }

    .footer {
      flex-direction: column;

      .footer-middle {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
      }
    }
  }
}