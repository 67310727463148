@import '../../../theme.scss';

.question-detail-component {
  padding: 0 0 40px 0;
  color: $blue600;

  h2 {
    font-size: 24px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 33px;
  }

  span {
    font-size: 16px;
    text-align: center;
    white-space: pre-line;
  }

  .question-detail-container {
    .left-content {
      .questions-list {
        padding-right: 15px;
      }
    }

    .right-content {
      display: flex;
      flex-wrap: nowrap;

      .answer-content {
          flex: 1;
          padding-right: 15px;
        }

      .summary {
        width: 35%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .question-detail-component {
    padding: 30px 0;

    h2 {
      font-size: 20px;
      line-height: 30px;
      text-align: center;
    }

    .row {
      flex-direction: column-reverse;
    }

    .question-detail-container {

      .right-content {
        flex-direction: column;
        flex-wrap: wrap;

        .summary {
          width: inherit;
        }
      }
    }
  }
}