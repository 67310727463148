@import '../../../theme.scss';

.footer-v2-component {
    color: #FFF;
    h1 {
        text-align: center;
    }
    .footer-content {
        align-items: center;
        .request-btn {
            cursor: pointer;
            font-size: 20px;
            font-weight: 700;
        }
        .yes-btn {
            background-color: $v2-color;
        }
        .no-btn{
            border: solid 1px #FFF;
        }
    }
    .footer {
        font-size: 12px;
        height: 70px;
        width: 100%;
        border-top: solid 0.1px rgba(231, 235, 238, 0.2);
    }
}

@media screen and (min-width: 992px) {
    .footer-v2-component {
        padding: 107px 0 0;
        background: url('../../../Assets/HomeV2/foote-bg.png') no-repeat;
        background-size: 100% 100%;
        // background-position: center;
        h1 {
            font-size: 36px;
            margin-bottom: 56px;
            line-height: 46px;
        }
        .footer-content {
            max-width: 639px;
            .request-btn {
                width: 210px;
                height: 60px;
            }
        }
        .arrow {
            position: relative;
            left: -256px;
            top: 164px;
        }
        .footer {
            margin-top: 150px;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer-v2-component {
        padding: 75px 0 0px;
        background: url('../../../Assets/HomeV2/reviews-bg.png') no-repeat;
        h1 {
            font-size: 24px;
            margin-bottom: 41px;
            line-height: 34px;
        }
        .footer-content {
            .request-btn {
                width: 158px;
                height: 60px;
            }
        }
        .arrow {
            display: none;
        }
        .footer {
            margin-top: 100px;
        }
    }
}