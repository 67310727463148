@import '../../theme.scss';

.bloglist-page {
    display: flex;
    flex-direction: column;
    font-family: Maax;
    color: $secondary-text;

    .bloglist-container {
        display: flex;
        flex-direction: column;

        .categories-area {
            h1 {
                font-weight: bold;
                margin: 0;
                font-size: 20px;
            }

            .category-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .category {
                    padding: 10px 18px;
                    cursor: pointer;
                    font-size: 15px;
                    color: #7B88A0;

                    &.selected {
                        background: #EEEFF3;
                        font-weight: 500;
                        border-radius: 20px;
                        color: $secondary-text;
                    }

                    &.category_plus {
                        color: $primary-text;
                    }
                }
            }
        }

        .article-area {
            .article-row {
                margin: 0;
            }
        }

        .btn-area {
            position: relative;
            margin: 0 auto;
            .load-more {
                width: 237px;
                z-index: 1;
                position: relative;
                border-color: $secondary-text;
                color: $secondary-text;
                background: white;
                font-size: 20px;
                font-weight: bold;
                outline: none;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .bloglist-page {
        padding-top: 40px;
        
        .bloglist-container {
            padding: 56px 0 103px;

            .categories-area {
                padding: 0 15px;
                h1 {
                    margin-bottom: 13px;
                }

                .category-list {
                    .category {
                        margin-right: 26px;
                    }
                }
            }

            .article-area {
                margin-top: 60px;
                .article-row {
                    margin-bottom: 57px;

                    .second-col {
                        padding-top: 60px;
                    }

                    &.first-row {
                        margin-bottom: 92px;
                    }
                }
            }

            .btn-area {
                margin-top: 90px;

                .load-more {
                    height: 63px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bloglist-page {
        .bloglist-container {
            padding: 27px 0 45px;

            .categories-area {
                padding: 0 30px;
                h1 {
                    margin-bottom: 25px;
                }
                .category-list {
                    .category {
                        margin-right: 3px;
                    }
                }
            }

            .article-area {
                margin-top: 45px;
            }

            .btn-area {
                margin-top: 19px;

                .load-more {
                    height: 65px;
                }
            }
        }
    }
}