@import '../../../theme.scss';

.question-summary-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 2rem;

  .question-summary-component {
      background-color: #13276F;
      padding: 25px;
      color: $white900;
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .right-description-content {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        span {
          opacity: 0.8;
        }
  
        .num-wrapper {
          background: no-repeat url('../../../Assets/PracticeQuestions/Summary-bg.png');
          background-position: center;
          background-size: contain;
          padding: 35px;
          margin-bottom: -15px;
  
          .questions-num {
            color: $green700;
          }
        }
      }
  
      .btn-3 {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
}

@media screen and (min-width: 992px) {
  .question-summary-wrapper {
    min-height: 378px;
    height: 378px;
    margin-left: 50px;
    margin-top: 0 !important;

    .question-summary-component {
      &::before {
        position: absolute;
        content: "";
        width: 8%;
        height: 100%;
        background-color: $green700;
        right: 100%;
        top: -8%
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 8%;
        bottom: 100%;
        left: 0;
        background-color: $green700;
      }
    }
  }
}