@import '../../../theme.scss';

.meet-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    .meet-container {
        display: flex;
        flex-direction: column;        
        .title {
            text-align: center;
            font-weight: bold;
        }
        .main-row {            
            .left-area {
                background: no-repeat url('../../../Assets/Home/meet-bg.png');
                background-position: center;
                background-size: 100% 100%;
                .image-container {
                    max-height: 300px;
                    width: 100%;
                    overflow: hidden;
                    border-radius: 5px;
                    img {
                        // position: relative;
                        display: block;
                        margin: auto;
                        // width: 100%;
                        z-index: 1;
                    }
                }
            }
            .right-area {
                display: flex;
                flex-direction: column;
                justify-content: space-around;                
                .meet-details {
                    font-size: 15px;                    
                }
                .meet-category {
                    .name {
                        font-size: 20px;
                        font-weight: bold;
                    }
                    .place {
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
        }
        .start-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .meet-component {
        padding: 50px 0;
        .meet-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }
            .main-row {
                padding: 30px 0px;
            }
            .start-btn {
                margin-top: 120px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .meet-component {
        padding: 0px 0 62px;
        .meet-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;
                .right-area {
                    margin-top: 20px;
                    .meet-details {
                        margin-bottom: 20px;                  
                    }
                }
            }
            .start-btn {
                margin-top: 74px;
            }
        }
    }
}