@import '../../../theme.scss';

.custom-toggle-component {
    position: relative;
    border-radius: 22.5px;
    cursor: pointer;
    background: $white100;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    // border: 1px solid $gray100;
  
    .handle {
        position: absolute;
        top: 3px;
        left: 64px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: white;
        transition: left 0.15s;
        -webkit-transition: left 0.15s;
        -moz-user-select: none;
        -webkit-user-select: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 2;
    }

    .theme {
        color: $black900;
        font-weight: bold;
        position: absolute;
        font-size: 12px;
        left: 12px;
        top: calc(50% - 8px);
    }
  
    &.disable {
      background: grey !important;

      .theme {
        display: none;
      }
    }
  
    &.off {
        background: $black900;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.02);

        .theme {
            color: white;
            left: 40px;
        }
        
        .handle {
            left: 3px;
        }
    }
}

@media screen and (min-width: 992px) {
    .custom-toggle-component {
        min-width: 120px;
        height: 39px;

        .handle {
            top: 3px;
            left: 84px;
            width: 33px;
            height: 33px;
        }
      
        &.off {
            .handle {
                left: 3px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .custom-toggle-component {
        min-width: 120px;
        height: 30px;

        .handle {
            top: 2px;
            left: 91px;
            width: 26px;
            height: 26px;
        }
      
        &.off {
            .handle {
                left: 2px;
            }
        }
    }
}