@import '../../../theme.scss';

.blogstartarea-component {
    font-family: Maax;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    background-color: #F9FAFB;
    overflow: hidden;

    .blogstartarea-container {
        .left-area {
            padding: 0;
            h1 {
                font-weight: bold;
                margin: 0;
            }

            p {
                font-weight: 500;
                margin: 0;
            }

            .form-area {
                .send-box {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    width: calc(100% - 12px);
                    .email-input {
                        flex: 1;
                        height: 100%;
                        border: 2px solid #E7EBEE;
                        z-index: inherit;
                    }
                    .email-input-not-fill {
                        flex: 1;
                        height: 100%;
                        border: 3px solid red;
                        z-index: inherit;
                    }
                    .send-btn {
                        height: 100%;
                        outline: none;
                        border: none;
                        color: white;
                        background-color: $primary-color;
                        z-index: inherit;
                        font-weight: bold;
                    }
                }
                .bg-dot {
                    left: 12px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .blogstartarea-component {
        .blog-header-bg {
            right: calc(50% - 645px) !important;
        }
    }
}

@media screen and (min-width: 992px) {
    .blogstartarea-component {
        padding: 46px 0 20px;
        height: 346px;
        .blog-header-bg {
            right: 0;
            position: absolute;
            bottom: 0;
        }
        .blogstartarea-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            .left-area {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                h1 {
                    font-size: 45px;
                    line-height: 57px;
                }

                .form-area {
                    padding-top: 30px;
                    .email-area {
                        display: inline-block;

                        p {
                            font-size: 20px;
                            margin-bottom: 34px;
                        }

                        .send-box {
                            height: 65px;
                            
                            .email-input {
                                font-size: 18px;
                                padding-left: 22px;
                            }
                            .send-btn {
                                width: 162px;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blogstartarea-component {
        padding-top: 40px;
        .blogstartarea-container {
            .left-area {
                h1 {
                    font-size: 26px;
                    line-height: 34px;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                    margin-top: 13px;
                }

                .form-area {
                    margin-top: 21px;
                    .send-box {
                        height: 50px;
                        margin-top: 21px;
                        
                        .email-input {
                            font-size: 13px;
                            padding-left: 17px;
                        }
                        .send-btn {
                            width: 111px;
                            font-size: 13px;
                        }
                    }
                }
            }
            .blog-header-bg {
                width: 100%;
                margin-top: -20px;
                margin-left: 30px;
            }
        }
    }
}