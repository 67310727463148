@import '../../../theme.scss';

.brand-v2-component {
    display: flex;
    flex-direction: column;

    .brand-container {
        .main-row {
            .left-area, .right-area {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .brand-v2-component {
        padding: 50px 0 0;
        .brand-container {
            
        }
    }
}

@media screen and (max-width: 991px) {
    .brand-v2-component {
        padding: 20px 20px;
        .brand-container {
            .col-lg-6 {
                padding-left: 0;
                padding-right: 0;
            }
            .item-area {
                margin: 10px 0;
            }
        }
    }
}