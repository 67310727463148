@import '../../../theme.scss';

.school-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: relative;
    height: 100%;
    
    .school-item {
        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        
        background: white;
        position: relative;
        z-index: 1;
        
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .item-logo {
                position: relative;
                width: 150px;
                z-index: 1;
            }
        }

        .description-container {
            padding: 30px;
            background-color: #473865;
            
            .item-title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .item-description {
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .school-component {
        .school-item {
            display: flex;
            flex-direction: row;
            height: 260px;
        }
    }
}

@media screen and (max-width: 991px) {
    .school-component {
        .school-item {
            
            .img-container {
                height: 200px;
            }
        }
    }
}