@import '../../../theme.scss';

.tutorfeatures-component {
    h1, h2, p {
        margin: 0;
    }
    h1 {
        text-align: center;
        margin-bottom: 33px;
        font-size: 34px;
        color: $secondary-text;
    }
    .sub-col {
        .bg-dot {
            width: initial;
            left: 30px;
            right: 0;
        }
    }
    .text-container {
        padding: 26px 0 24px 30px;
        box-shadow: 0 9px 45px rgba($color: #000000, $alpha: 0.08);
        height: 100%;
        position: relative;
        background: white;
        z-index: 2;
        color: $secondary-text;
        h2 {
            font-size: 28px;
            margin-bottom: 20px;
            color: $secondary-text;
        }
        .content-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            p {
                font-size: 15px;
                line-height: 24px;
                color: $secondary-text;
                margin-left: 10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .tutorfeatures-component {
        padding-top: 115px;
    }
}

@media screen and (max-width: 991px) {
    .tutorfeatures-component {
        padding-top: 73px;
        .sub-col {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}