@import '../../../theme.scss';

.read-component {
    font-family: Maax;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #000;
    padding: 50px 0;
    
    

    .description {
        margin-left: 10px;
        font-size: 17px;
    }
    .body {
        margin-top: 60px;
       
    }
}

@media screen and (min-width: 992px) {
    .read-component {
        h2 {
            font-size: 30px;
        }

        .title {
            display: flex;
            align-items: center;
        }
        
    }
}

@media screen and (max-width: 991px) {
    .read-component {
        h2 {
            font-size: 24px;
        }
    }
    
}