@import '../../../theme.scss';

.feedback-modal-component {
    .modal-dialog {
        width: 100%;
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
            border: none;
            outline: none;
            background: none;
            position: relative;
            height: 100%;

            .feedback-content {
                display: flex;
                margin: auto;
                background: white;
                position: relative;
                width: 100%;
                border-radius: 8px;
                .close-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: -30px;
                    fill: white;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    // background: rgba(255, 255, 255, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon-close {
                        width: 100%;
                        height: 100%;
                        div {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transform: rotate(90deg);
                        }
                    }
                }
                .sub-feedback {
                    height: 100%;
                    max-height: 100%;
                    .feedback {
                        display: flex;
                        height: fit-content;
                        flex: 1;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .modal-content {
        padding: 0 30px;
    }
    .feedback-content {
        max-width: 689px;
        height: 386px;
        .sub-feedback {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 0 15px;
    }
    .feedback-content {
        max-width: 345px;
        flex-direction: column;
        max-height: 542px;
        height: 100%;
    }
}