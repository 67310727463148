@import '../../../theme.scss';

.header-v3-component {
    font-size: 15px;
    position: fixed;
    width: 100%;
    color: white;
    z-index: 10;
    height: 70px;
    font-family: Maax;
    font-weight: 500;
    top: 40px;

    .normal-container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        .menu {
            display: flex;
            align-items: center;

            .menu-item {
                margin-right: 25px;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &.active {
                    font-weight: bold;
                }
            }

            .sign-up {
                color: white;
                background-color: $v2-color;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.transform-header-v3 {
    background-color: white;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
    .menu-item {
        color: $secondary-text !important;
    }
    .sign-up {
        color: white !important;
    }
}

@media screen and (max-width: 992px) {
    .header-v3-component {
        top: 0 !important;
    }
}