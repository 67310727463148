@import '../../../theme.scss';

.articlepreview-component {
    position: relative;
    font-family: Maax;
    color: $secondary-text;
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .bg {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    .article-info {
        background: white;
        display: flex;
        flex-direction: column;
        z-index: 2;
        position: relative;

        h1 {
            font-weight: bold;
            margin: 0;
        }

        .sub-info {
            display: flex;
            align-items: center;
        }

        .property-info {
            .category {
                border-radius: 15.5px;
                background: #EEEFF3;
                font-weight: 500;
                text-transform: uppercase;
            }

            .last_read {
                font-size: 13px;
                line-height: 19px;
                color: $third-text;
            }
        }

        .detail-info {
            .description {
                flex: 1;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                color: $third-text;
                font-size: 15px;
                line-height: 22px;
            }
            .read-btn {
                border: 2px solid $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .articlepreview-component {
        min-height: 489px;
        .bg {
            width: calc(100% - 30px);
            max-height: 489px;
        }
        .custom-bg-dot {
            width: initial;
            height: initial;
            left: 15px;
            right: 15px;
            bottom: -38px;
            z-index: 0;
            top: 20px;
        }
        .article-info {
            margin: auto 0 -26px 30px;
            padding: 23px 30px 26px 30px;
            width: calc(100% - 30px);
            
            h1 {
                font-size: 28px;
                line-height: 37px;
            }

            .property-info {
                margin-top: 13px;
                .category {
                    font-size: 12px;
                    padding: 11px 12px;
                    margin-right: 12px;
                }
            }

            .detail-info {
                margin-top: 11px;
                .description {
                    padding-right: 52px;
                    -webkit-line-clamp: 3;
                }
                .read-btn {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        &.first {
            min-height: 400px;
            .bg {
                width: 100%;
                height: 400px;
            }
            .custom-bg-dot {
                width: calc(50% - 45px);
            }
            .article-info {
                width: calc(50% - 45px);
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .articlepreview-component {
        min-height: 300px;
        margin-bottom: 30px;
        .bg {
            max-height: 300px;
            width: calc(100% - 18px);
        }
        .custom-bg-dot {
            width: initial;
            height: initial;
            left: 8px;
            right: 8px;
            bottom: -10px;
            z-index: 0;
            top: 113px;
        }
        .article-info {
            width: calc(100% - 18px);
            margin: 150px 0 0 18px;
            padding: 24px 20px;

            h1 {
                font-size: 26px;
                line-height: 34px;
            }

            .property-info {
                margin-top: 20px;
                .category {
                    font-size: 11px;
                    padding: 5px 14px;
                    margin-right: 15px;
                }
            }

            .detail-info {
                margin-top: 10px;
                .description {
                    padding-right: 20px;
                    -webkit-line-clamp: 2;
                }
                .read-btn {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}