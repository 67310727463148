@import '../../../theme.scss';

.blog-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .blog-container {
        display: flex;
        flex-direction: column;
        
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;
        }

        .main-row {
            
        }
    }
}

@media screen and (min-width: 992px) {
    .blog-component {
        padding: 50px 0;
        .blog-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                padding: 30px 0px;

                
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blog-component {
        padding: 0px 0 62px;
        .blog-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;
                
            }
        }
    }
}