@import '../../theme.scss';

.availavbility-section {
    
    .pre-setting {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 24px;
        .help-area {
            display: flex;
            .help-item {
                margin-right: 52px;

                .help-color {
                    width: 14px;
                    height: 14px;
                    border-radius: 2px;
                    margin-right: 7px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.available {
                        background: white;
                        border: 1px solid #F0F1F2;
                    }
                    &.unavailable {
                        background: #B3E1F7;
                    }
                }
                p {
                    font-size: 13px;
                    text-transform: capitalize;
                }
            }
        }
        .pre-explain {
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 20px;
            color: #7B88A0;
        }
        .delete-all {
            color: $main-bg;
            cursor: pointer;
            width: fit-content;
            p {
                margin-left: 7px;
                font-size: 13px;
            }
        }
    }
    .setting-area {
        overflow-x: auto;
        .setting-table {
            width: 100%;
            font-size: 15px;
            .start-col {
                min-width: 55px;
                width: 55px;
            }
            .header-col {
                min-width: 120px;
                text-align: center;
                font-weight: normal;
                p {
                    font-size: 15px;
                    margin-bottom: 19px;
                }
                &.active {
                    color: $primary-color;
                }
            }
            td, th {
                border: 1px solid #F0F1F2;
                border-collapse: collapse;
                margin: 0;
                padding: 0;
                &:first-child {
                    border-left: none;
                    cursor: default;
                    padding-right: 8px;
                    text-align: right;
                    color: #7B88A0;
                }
            }
            th {
                border-top: none;
                color: #7B88A0;
            }
            tbody {
                .unavailable, .booked {
                    border-top: none;
                    border-bottom: none;
                }
                td {
                    height: 46px;
                    cursor: pointer;
                }
                .inner-schedule {
                    width: calc(100% - 8px);
                    height: 100%;
                    border-radius: 4px;
                    background: white;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .del-btn {
                        width: 23px;
                        height: 23px;
                        background: white;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 2px 6px rgba($color: #000000, $alpha: 0.2);
                        right: -27px;
                        position: absolute;
                        z-index: 10;
                        svg {
                            width: 9px;
                            fill: #7B88A0;
                        }
                    }
                    &.has-prev {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                    }
                    &.has-next {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    &.unavailable {
                        background: #B3E1F7;
                    }
                    &.booked {
                        background: #24C1A5;
                        color: white;
                    }
                    &.selected {
                        background: $primary-color;
                        box-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.25);
                        color: white;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .availavbility-section {
        .pre-setting {

            .help-area {                
                .help-item {
                    margin-bottom: 17px;
                }
            }
            .pre-explain {
                margin-top: 7px;
            }
            .delete-all {
                margin: 10px 0;
            }
        }
        .setting-area {
            flex: 1;
        }
    }
}

@media screen and (max-width: 991px) {
    .availavbility-section {
        flex-direction: column;
        .pre-setting {            
            .help-area {
                .help-item {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .setting-table {
            .header-col {
                p {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}