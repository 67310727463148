@import '../../../theme.scss';

.header-component {
    font-size: 15px;
    position: fixed;
    width: 100%;
    color: white;
    z-index: 10;
    height: 70px;
    font-family: Maax;
    font-weight: 500;
    top: 40px;

    .normal-container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        .menu {
            display: flex;
            align-items: center;

            .menu-item {
                color: #203662;
                margin-right: 25px;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    text-decoration: none;
                }

                &.active {
                    font-weight: bold;
                }
            }

            .sign-up {
                color: white;
                background-color: #77D572;
                padding-left: 17px;
                padding-right: 17px;
            }
        }
        .hamburger {
            fill: white;
        }
    }
}


@media screen and (min-width: 1200px) {
    .header-component {
        .normal-container {
            .extra-menu {
                display: flex !important;
            }
            .extra-item {
                display: none !important;
            }
            .menu {
                .menu-item {
                    margin-right: 40px !important;

                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .header-component {
        .normal-container {            
            .hamburger {
                display: none;
            }
            .extra-menu {
                display: none;   
            }
            .extra-item {
                display: flex;
            }
        }
    }
    .signup-header-component {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .header-component {
        top: 0 !important;
        
        .normal-container {
            .menu {
                display: none;
            }
            .sign-up {
                color: white;
                background-color: #77D572;
                padding-left: 17px;
                padding-right: 17px;
            }
            .header-start-button {
                display: flex;
                align-items: center;
                background: $green-color;
                padding: 10px 20px 10px 20px;
                color: white;
                cursor: pointer;
            }
        }
        .signin-container {
            background-color:white;
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        }
    }
    .signup-header-component {
        background-color: white;
    }
}

.transform-header {
    background-color: white;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
    .menu-item {
        color: $secondary-text !important;
    }
    .sign-up {
        // border: 2px solid $secondary-text !important;
        color: white !important;
    }
    .hamburger {
        fill: $secondary-text !important;
    }
}

.signin-container {
    max-width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
}

.signup-header-component {
    position: fixed;
    width: 100%;
    z-index: 10;
    height: 70px;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);

    .signup-container {
        max-width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
    }
}