@import '../../../theme.scss';

.blogfooter-component {
    font-family: Maax;
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: $primary-bg;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .blogfooter-container {
        .main-row {
            .left-area {
                h1 {
                    font-weight: bold;
                    margin: 0;
                }

                p {
                    font-weight: 500;
                    margin: 0;
                }

                .form-area {
                    .email-area {
                        max-width: 100%;
                        display: inline-block;
                        .send-box {
                            display: flex;
                            .email-input {
                                height: 100%;
                                flex: 1;
                                border: 3px solid #E7EBEE;
                            }
                            .email-input-not-fill {
                                flex: 1;
                                height: 100%;
                                border: 3px solid red;
                                z-index: inherit;
                            }
                            .send-btn {
                                height: 100%;
                                outline: none;
                                border: none;
                                color: white;
                                background-color: $primary-color;
                                font-weight: bold;
                            }
                        }

                        .explain {
                            font-size: 13px;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .blogfooter-component {
        .blogfooter-container {
            .main-row {
                .right-area {
                    img {
                        width: 85% !important;
                        object-fit: scale-down;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .blogfooter-component {
        padding: 63px 0;
        display: flex;
        flex-direction: column;
        .blogfooter-container {
            display: flex;
            flex-direction: column;

            .main-row {
                .left-area {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    h1 {
                        font-size: 45px;
                        line-height: 57px;
                    }

                    p {
                        font-size: 24px;
                        line-height: 40px;
                        margin-top: 35px;
                    }

                    .form-area {
                        margin-top: 43px;
                        .email-area {
                            .send-box {
                                height: 65px;
                                .email-input {
                                    font-size: 18px;
                                    padding-left: 22px;
                                }
                                .send-btn {
                                    width: 162px;
                                    font-size: 20px;
                                }
                            }
    
                            .explain {
                                margin-top: 16px;
                            }
                        }
                    }
                }

                .right-area {
                    img {
                        width: 100%;
                        margin-left: auto;
                        object-fit: scale-down;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .blogfooter-component {
        padding: 24px 0;
        .blogfooter-container {
            .main-row {
                .left-area {
                    padding: 0 30px;
                    h1 {
                        font-size: 26px;
                        line-height: 34px;
                    }

                    img {
                        margin-top: 20px;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-top: 23px;
                    }

                    .form-area {
                        margin-top: 22px;
                        .email-area {
                            .send-box {
                                height: 50px;
                                .email-input {
                                    font-size: 15px;
                                    padding-left: 17px;
                                }
                                .send-btn {
                                    width: 125px;
                                    font-size: 15px;
                                }
                            }
    
                            .explain {
                                margin-top: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}