@import '../../../theme.scss';

.tvMedia-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: relative;
    height: 100%;
    
    .tvMedia-item {
        background: white;
        position: relative;
        z-index: 1;
        
        .img-container {
            width: 100%;
            height: 250px;
            overflow-y: hidden;
            .meta-image {
                width: 100%;
                height: 100%;
            }
        }

        .play-btn-bg {
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $frog-main-color;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .play-icon {
                font-size: 30px;
                color: #FFF;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .tvMedia-component {
        .tvMedia-item {           
            // height: 260px;
        }
    }
}

@media screen and (max-width: 991px) {
    .tvMedia-component {
        .tvMedia-item {
            
        }
    }
}