@import '../../../theme.scss';

.hours-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .hours-container {
        display: flex;
        flex-direction: column;        
        .title {
            text-align: center;
            font-weight: bold;
        }
        .main-row {
            .item-container {
                display: flex;
                flex-direction: column;
                padding: 0 20px;
                .item-area {
                    box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                    border-radius: 10px;
                    height: 210px;
                    padding: 20px 30px;
                    .item-title {
                        font-size: 20px;
                        font-weight: bold;
                        text-align: center;
                    }                    
                    .sub-item {
                        display: flex;
                        margin-top: 20px;
                        .time-container {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .hours-component {
        padding: 70px 0;
        .hours-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }
            .main-row {
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .hours-component {
        padding: 0px 0 62px;
        .hours-container {
            .title {
                font-size: 28px;
            }
            .main-row {
                padding: 10px 0;
                .item-container {
                    margin-top: 20px;
                }
            }
        }
    }
}