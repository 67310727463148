.checkbox-component {
    background-color: white;
    border: 2px solid #D8D9DB;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 3px;

    .svg-icon {
        display: flex;
        align-items: center;
        justify-self: center;
        fill: #23A4EF;
    }
}