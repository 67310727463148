@import '~bootstrap/scss/bootstrap.scss';
@import './theme.scss';
@import './custom.scss';

@font-face {
  font-family: 'Maax';
  font-weight: normal;
  src: url('./Assets/Font/Maax.ttf') format('truetype');
}
@font-face {
  font-family: 'Maax';
  font-weight: bold;
  src: url('./Assets/Font/Maax-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Maax';
  font-weight: 500;
  src: url('./Assets/Font/Maax-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  src: url('./Assets/Font/ProximaNova-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: bold;
  src: url('./Assets/Font/ProximaNova-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNova';
  font-weight: 600;
  src: url('./Assets/Font/ProximaNova-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProductSans';
  font-weight: normal;
  src: url('./Assets/Font/ProductSans.ttf') format('truetype');
}
@font-face {
  font-family: 'ProductSans';
  font-weight: bold;
  src: url('./Assets/Font/ProductSans-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Maax;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.modal-open {
    overflow: hidden;
  }
}

h1, h2, h3, h4, p {
  margin: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #main {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .content {
      padding-top: 70px;
    }

    .header-component {
      filter: none;
    }

    &.blur {
      .content {
        filter: blur(4px);
      }

      .header-component {
        filter: blur(4px);
      }
      
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(#203662,0.45);
        z-index: 15;
      }
    }
  }
}

.primary-button {
  font-weight: bold;
  font-size: 15px;
  font-family: Maax;
  border-color: $primary-color;
  background: $primary-color;
}

.show-mobile {
  display: block;
}

.show-web {
  display: none;
}

.show-web-flex {
  display: none;
}

.show-mobile-flex {
  display: flex;
}

@media screen and (min-width: 992px) {
  .show-web {
    display: block;
  }

  .show-web-flex {
    display: flex;
  }

  .show-mobile {
    display: none;
  }

  .show-mobile-flex {
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #1B1F25; 
    border-radius: 40px;
  }

  ::-webkit-scrollbar {
  width: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
  background: #BDBDBD; 
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.dot-bg {
  width: 100%;
  height: 63px;
  margin-left: 13px;
  margin-top: -52px;
  z-index: 0 !important;
  position: relative;
}

.bg-dot {
  position: absolute;
  width: calc(100% + 6px);
  height: 100%;
  left: 6px;
  bottom: -12px;
  z-index: 0;
  background: url('./Assets/Common/bg-dot.png');
}

.small-dot {
  height: 63px;
}

.span-dot {
  width: 100%;
  left: 14px;
}

.row-dot {
  width: calc(100% + 24px);
}

// .shadow-object {
//   box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
// }

.icon-close {
  transform: rotate(45deg);
  cursor: pointer;
}

input, textarea {
  box-shadow: none;
  outline: none;
}

.under-dot {
  position: absolute;
  left: 15px;
  top: 10px;
  bottom: -10px;
  right: -15px;
  z-index: 0;
  background: url('./Assets/Common/bg-dot.png');
}

::placeholder {
  color: #C5CBD7;
}

.input-icon {
  fill: #C5CBD7;
}

.overlay-loader {
  z-index: 1010;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000050;
}
