@import '../../../theme.scss';

.question-list-component {
  padding: 0 0 40px 0;
  color: $blue600;

  h2 {
    font-size: 32px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 60px;
  }

  span {
    font-size: 16px;
    text-align: center;
    white-space: pre-line;
  }

  .home-start-container {
    .left-content {
      .sub-description {
        font-size: 16px;
        color: $blue600;
      }

      .load-more-btn {
        margin-top: 30px;
        margin-bottom: 20px;
        display: table;
        margin-left: auto;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .question-list-component {
    padding: 30px 0;

    h2 {
      font-size: 28px;
      line-height: 50px;
      text-align: center;
    }

    .sub-description {
      text-align: center;
    }

    .home-start-container {
      .left-content {}
    }
  }
}