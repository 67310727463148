@import '../../../theme.scss';

.top-menu-component {
    align-items: center;
    background: $blue600;
    color: #fff;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 11;

    .v-c {
        display: flex;
        align-items: center;
    }

    .tag {
        color: white;

        &.grade-getter {
            color: $blue800;
        }
        &:hover {
            text-decoration: none;
            color: $blue800;
        }
    }

    .dot {
        margin: 0 20px;
        opacity: .2;
    }
}

@media screen and (max-width: 992px) {
    .top-menu-component {
        display: none;
    }
}