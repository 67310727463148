@import '../../theme.scss';

.summer-page {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    background: #F9FAFB;

    .summer-container {
        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        background: white;
        z-index: 1;            
        .main-row {
            .summer-right {
                padding: 50px 45px 45px 45px;
                .summer-block {
                    margin-bottom: 50px;

                    .summer-share {
                        display: flex;
                        align-items: center;
                        margin-top: 30px;
                        .share-description {
                            font-size: 17px;
                            margin-right: 20px;
                        }
            
                        .share-btn {
                            margin: 0 10px;
                        }
                    }

                    .summer-banner {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                    }

                    .item-title {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 15px;
                    }
                    
                    .sub-title-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .item-sub-title {
                        font-size: 15px;
                        cursor: pointer;                       
                    }

                    .item-sub-active {
                        color: $frog-main-color;
                    }

                    .item-sub-inActive {
                        opacity: 0.6;
                    }

                    .item-description {                        
                        margin-bottom: 15px;
                    }                    

                    .max-transparency {
                        .price-table-block {
                            margin: 50px 0 50px;
                            .price-table {
                                box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
                                background: white;
                                z-index: 2;
                                position: relative;
                                background: white;
                                p {
                                    font-weight: bold;
                                }
    
                                .table-item {
                                    display: flex;
                                    align-items: center;
                                    
                                    .table-left {
                                        padding: 20px 0 20px 20px;
                                        border-right: #E7EBEE solid 2px;
                                    }
                                    .table-left1 {
                                        padding: 20px 0 20px 20px;
                                        border-right: #E7EBEE solid 2px;
                                        background: #F9FAFB;
                                    }
                                    .table-right {
                                        padding: 20px 0 20px 20px;
                                    }   
                                    .table-right1 {
                                        padding: 20px 0 20px 20px;
                                        background: #F9FAFB;
                                    }
                                    .total-cost {
                                        font-size: 21px;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }             
                    }

                    .program-options {
                        margin-left: 30px;
                    }

                    .owl-theme {
                        .owl-nav {
                            .owl-prev, .owl-next {
                                outline: none;
                                &:hover {
                                    background: none;
                                }
                            }
                        }
                        .owl-stage-outer {
                            padding-top: 30px;
                            padding-bottom: 30px;
                            .owl-stage {
                                display: flex;
                            }
                        }
                    } 

                    .schedule-btn-container{
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .schedule-btn {
                            background-color: $frog-main-color;
                            padding: 10px;
                            width: 300px;
                            height: 50px;
                            font-size: 20px;
                            color: #FFF;
                            margin-top: 30px;
                            cursor: pointer;
                        }
                    }
                }


                .final-block{
                    .sincerely-mail {                        
                        margin: 7px 0 7px;                        
                    }
                    .ceo-name {                        
                        font-size: 15;
                        font-weight: bold;
                    }
                }
            }

            .summer-left {
                padding: 50px 35px 35px 35px;
                background: #F9FAFB;
                
                .schedule-sticky {
                    position: sticky;
                    top: 100px;
                }

                .ceo-img-name {
                    border-bottom: 1px solid #E7EBEE;
                    .ceo-photo {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                    }
                    .ceo-name {
                        font-size: 20px;
                        font-weight: bold;
                        margin: 10px 0 10px;
                    }
                    .ceo-mail {
                        margin-bottom: 25px;
                    }                        
                }

                .ceo-phone-mail {
                    border-bottom: 1px solid #E7EBEE;
                    padding: 10px 0 10px;
                    .phone-mail-item {
                        display: flex;
                        align-items: center;
                        margin: 15px 0 15px;
                        .des {
                            margin-left: 10px;
                        }
                    }
                }

                .schedule-btn-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $frog-main-color;
                    padding: 10px;
                    width: 100%;
                    height: 40px;
                    font-size: 17px;
                    color: #FFF;
                    margin-top: 30px;
                    cursor: pointer;
                }

                .schedule-left-area {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 30px;
                    .schedule-title {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
            }
        }
        
    }
}

@media screen and (min-width: 992px) {
    .summer-page {
        padding: 90px 100px 108px;
        background: no-repeat url('../../Assets/Home/values-bg.png');
        background-position: center;
        background-size: 100% 100%;
        color: #203662;
        font-size: 15px;
        .summer-container {
                       
            .main-row {
                .summer-right {
                    .summer-block {
                        .owl-theme {
                            .owl-nav {
                                position: absolute;
                                display: flex;
                                justify-content: space-between;
                                top: 50%;
                                transform: translateY(-50%);
                                margin: 0;
                                left: -30px;
                                right: -30px;
                            }
                        }
                    }
                }

                .summer-left {
                   
                }
            }
            
        }
    }
}

@media screen and (max-width: 991px) {
    .summer-page {
        padding: 50px 0 108px;
        background: no-repeat url('../../Assets/Home/values-bg.png');
        background-position: center;
        background-size: 100% 100%;
        color: #203662;
        font-size: 15px;
        .summer-container {
                     
            .main-row {
                .summer-right {
                    .summer-block {
                        .owl-theme {
                            .owl-nav {
                                .owl-prev, .owl-next {
                                    outline: none;
                                    &:hover {
                                        background: white;
                                    }
                                }
                                .owl-prev {
                                    margin: 0 15px 0 5px;
                                }
                                .owl-next {
                                    margin: 0 5px 0 15px;
                                }
                            }
                        }
                    }
                }
                
                .summer-left {                    
                   

                }
            }
            
        }
    }
}