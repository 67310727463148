.avatar-component {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #24C1A5;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%;
    }
    &.has-photo {
        background: none !important;
    }
}