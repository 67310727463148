@import '../../../theme.scss';

.latest-video-component {
  .latest-video-chanel {
    display: inline-block;

    &:hover {
      cursor: pointer;
      -ms-transform: scale(1.1);
      /* IE 9 */
      -webkit-transform: scale(1.1);
      /* Safari 3-8 */
      transform: scale(1.15);
      transition: 1s;

      .video-thumb-content {
        .play-btn-content {
          &.play-hover {
            display: inline-flex;
          }
        }
      }

      .description-content {
        .des-hover {
          display: block;
        }
      }
    }

    .video-thumb-content {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: black;
      height: 350px;
      border: solid 1px #FFF;

      .video-thumb-img {
        width: auto;
        height: 100%;
      }

      .play-btn-content {
        position: absolute;
        z-index: 10;
        left: calc(50% - 45px);
        top: calc(50% - 50px);
        width: 120px;
        height: 70px;
        background-color: $secondary-bg;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;

        &.play-hover {
          display: none;
        }

        .play-icon {
          width: 70px;
          font-size: 50px;
        }
      }
    }

    .description-content {
      max-width: 600px;

      .des-hover {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .latest-video-component {
    .latest-video-chanel {
      .video-thumb-content {
        width: 100%;
        height: auto;

        .video-thumb-img {
          width: 100%;
          height: auto;
        }

        .play-btn-content {
          width: 90px;
          height: 60px;
          top: calc(50% - 30px);

          &.play-hover {
            display: inline-flex;
          }
        }
      }

      .description-content {
        max-width: 100%;
  
        .des-hover {
          display: block;
        }
      }
    }
  }
}