@import '../../../theme.scss';

.contacts-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .contacts-container {
        display: flex;
        flex-direction: column;
        
        .title {
            text-align: center;
            font-weight: bold;
        }

        p {
            font-size: 20px;
            margin-left: 10px;
        }

        .main-row {
            .map-details {
                .item-area {
                    display: flex;
                }
            }

            .map-area {
                // height: 200px;
                // padding: 0px;
                .map-wrapper {
                    width: 100%;
                    padding-top: 25%;
                    position: relative;
    
                    .map-container {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }
        
    }
}

@media screen and (min-width: 992px) {
    .contacts-component {
        padding: 70px 0;
        .contacts-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                .map-details {
                    .item-area {
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .contacts-component {
        padding: 0px 0 62px;
        .contacts-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;

                .map-area { 
                    margin-top: 20px;
                }
            }
        }
    }
}