@import '../../../theme.scss';


.ceo-component {
    color: $dark-color;
    p {
        font-size: 15px;
    }
    .ceo-content {
        border: solid 5px #FFDF05;
        display: flex;
        flex-direction: column;
        align-items: center;
        .description {
            font-weight: 500;
            .strong-bg {
                background: no-repeat url('../../../Assets/HomeV2/strong-bg-1.png');
                background-position: center bottom;
                background-size: 100% 100%;
                padding: 5px 10px;
                color: #FFF;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .ceo-component {
        padding: 0px 0px 70px;
        p {
            line-height: 16.55px;
        }
        .marker-content {
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            .marker {
                margin-left: 64px;
                margin-bottom: -25px;
                background-color: #FFF;
                padding: 0 10px;
            }
        }
        .ceo-content {
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            .description {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ceo-component {
        padding: 50px 0px;
        p {
            line-height: 20px;
        }
        .marker-content {
            margin-top: 15px;
            .marker {
                margin-left: 50px;
                margin-bottom: -25px;
                background-color: #FFF;
                padding: 0 10px;
            }
        }
        .ceo-content {
            padding: 13px 10px;
            .description {
                font-size: 18px;
            }
        }
    }
}