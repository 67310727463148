@import '../../../theme.scss';

.tutorjobs-component {
    color: $secondary-text;
    h1, h2, p {
        margin: 0;
        color: $secondary-text;
    }
    h1 {
        text-align: center;
        margin-bottom: 33px;
        font-size: 34px;
    }
    .avatar {
        border-radius: 50%;
        color: white;
        font-size: 28px;
        font-weight: bold;
        &.user-0 {
            background: #24C1A5;
        }
        &.user-1 {
            background: #4624C1;
        }
        &.user-2 {
            background: #C124B4;
        }
    }
    .bg-dot {
        height: 66%;
        left: 12px;
        width: initial;
        right: -12px;
    }
    .jobs-list {
        box-shadow: 0 9px 45px rgba($color: #000000, $alpha: 0.08);
        height: 533px;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        .jobs {
            position: relative;
            z-index: 2;
            padding: 0 30px;
            background: white;
            .job-item {
                display: flex;
                padding: 30px 0;
                border-bottom: 1px solid #E7EBEE;
                justify-content: space-between;
                &:last-child {
                    border-bottom: none;
                }
                .user-area {
                    .avatar {
                        width: 90px;
                        height: 90px;
                        margin-right: 30px;
                    }
                }
                .user-info {
                    h2 {
                        font-size: 28px;
                        margin-bottom: 9px;
                    }
                    p {
                        font-size: 18px;
                    }
                }
                .content-list {
                    max-width: 470px;
                    .content-item {
                        display: flex;
                        font-size: 18px;
                        line-height: 30px;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .job-mobile-container {
        box-shadow: 0 9px 45px rgba($color: #000000, $alpha: 0.08);
        position: relative;
        .job-item {
            padding: 30px;
            position: relative;
            z-index: 2;
            background: white;
            .user-area {
                padding-bottom: 30px;
                border-bottom: 1px solid #E7EBEE;
                .avatar {
                    width: 75px;
                    height: 75px;
                    margin-right: 15px;
                }
                .user-info {
                    h2 {
                        font-size: 26px;
                        margin-bottom: 11px;
                    }
                    p {
                        font-size: 15px;
                    }
                }
            }
            .content-list {
                margin-top: 28px;
                .content-item {
                    display: flex;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .owl-theme {
        .owl-nav {
            .owl-prev, .owl-next {
                outline: none;
                &:hover {
                    background: none;
                }
            }
        }
        .owl-stage-outer {
            padding-top: 30px;
            padding-bottom: 30px;
            .owl-stage {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .tutorjobs-component {
        padding: 120px 0;
        .start-btn {
            margin: 120px auto 0 auto;
        }
    }
}

@media screen and (max-width: 991px) {
    .tutorjobs-component {
        padding: 70px 0;
        .owl-theme {
            .owl-nav {
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 22px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 22px;
                }
            }
        }
        .start-btn {
            margin: 75px auto 0 auto;
        }
    }
}