@import '../../theme.scss';

.faqs-page {
    display: flex;
    flex-direction: column;
    font-family: Maax;
    color: $secondary-text;

    .faqs-container {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-weight: bold;
        }

        .faqs-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: white;

            .accordion {
                border-bottom: 1px solid #E7EBEE;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .faqs-page {
        .faqs-container {
            max-width: 930px;
        }
    }
}

@media screen and (min-width: 992px) {
    .faqs-page {
        padding: 103px 0 120px;
        .faqs-container {
            .title {
                font-size: 45px;
                line-height: 57px;
                margin-bottom: 103px;
            }

            .faqs-list {
                padding: 0 30px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .faqs-page {
        padding: 66px 0 88px;
        .faqs-container {
            .title {
                font-size: 34px;
                line-height: 45px;
                margin-bottom: 66px;
            }

            .faqs-list {
                padding: 0 15px;
            }
        }
    }
}