@import '../../../theme.scss';

.video-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    p {
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }

    .video-container {
        display: flex;
        flex-direction: column;
        
        .title {
            text-align: center;
            font-weight: bold;
        }

        .main-row {
            .item-area {
                display: flex;
                flex-direction: column;

                .play-btn-bg {
                    position: absolute;
                    z-index: 10;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                    background-color: #23A4EF;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .play-icon {
                        font-size: 50px;
                        color: #FFF;
                    }
                }
                video {
                    object-fit: fill;
                    position: relative;
                    border-radius: 5px;
                }
                
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .video-component {
        padding: 70px 0;
        .video-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }
            .main-row {
                .player-area {
                    height: 315px;
                }
                .item-area {
                    .play-btn-bg {
                        width: 79px;
                        height: 79px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .video-component {
        padding: 50px 0px 0px;
        .video-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                .item-area {
                    padding: 0px 15px 60px;
                    .player-area {
                        height: 220px;
                    }
                    .play-btn-bg {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}