@import '../../../theme.scss';

.about-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .about-container {
        display: flex;
        flex-direction: column;
        
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;

        }

        .main-row {
            .left-area {

                display: flex;
                flex-direction: column;
                justify-content: space-around;
                
                .about-details {
                    font-size: 15px;                    
                }
            }
            .right-area {
                background: no-repeat url('../../../Assets/Home/about-bg.png');
                background-position: center;
                background-size: 100% 100%;


                .image-container {
                    // position: relative;
                }

                img {
                    position: relative;
                    width: 100%;
                    z-index: 1;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .about-component {
        padding: 70px 0;
        .about-container {
            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                padding: 30px 0px;

                .left-area {
                    

                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .about-component {
        padding: 0px 0 62px;
        .about-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                padding: 10px 0;
                .left-area {
                    
                    .about-details {
                        margin-bottom: 20px;                  
                    }

                }

                .right-area {

                }
            }
        }
    }
}