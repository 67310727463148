@import '../../../theme.scss';

.ws-subject-header-component {
    padding: 40px 0;
    color: $white900;
    margin-top: 40px;

    h1 {
        font-size: 44px;
        font-weight: 700;
        white-space: pre-line;
        line-height: 60px;
        padding: 5px 0 10px;
        color: white;
    }

    .subject-start-container {
        background: no-repeat url('../../../Assets/Worksheets/header-bg.png');
        background-position: center;
        height: 299px;

        .row {
            height: 100%;
        }

        .left-content {
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: flex-end;
            padding: 10px 45px 35px;
        }

        .right-content {
            padding-right: 0;

            .header-img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ws-subject-header-component {
        padding: 0;

        h1 {
            font-size: 24px;
            text-align: center;
            line-height: 50px;
        }

        .left-content {
            padding-top: 30px;
        }
    }
}

@media screen and (min-width: 992px) {
   .ws-subject-header-component {
        .subject-start-container {
            min-height: 263px;
            background-size: cover;
        }
   }
}