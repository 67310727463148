@import '../../../theme.scss';

.watch-component {
  font-family: Maax;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 50px 0;

  .description {
    margin-left: 10px;
    font-size: 17px;
  }

}

@media screen and (min-width: 992px) {
  .watch-component {
    h2 {
      font-size: 30px;
    }

    .title {
      display: flex;
      align-items: center;
    }

    .body {
      margin-top: 60px;

      .chanel-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 348px;

        .big-chanel-img {
          padding: 0px;
          background-repeat: no-repeat;
          background-size: cover;
          
          .big-chanel-item {
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0), $secondary-bg);

            padding: 200px 0px 67px 20px;
            color: #FFF;

            .chalel-logo {
              height: 30px;
              margin-bottom: 30px;
            }

            .big-changel-title-category {
              font-size: 14px;
            }

            .big-changel-title {
              font-size: 20px;
              font-weight: 500px;
            }

            .big-changel-rule {
              font-size: 13px;
            }
          }

          &:hover {
            cursor: pointer;
            -ms-transform: scale(1.1);
            /* IE 9 */
            -webkit-transform: scale(1.1);
            /* Safari 3-8 */
            transform: scale(1.15);
            transition: 1s;
            z-index: 9;
            margin-top: 100px;
          }

          .big-chanel-item-hover {
            background: linear-gradient(rgba(0, 0, 0, 0), $secondary-bg);
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.5);
            z-index: 3;
            color: #FFF;
            padding-top: 250px;

            .big-changel-title-category {
              font-size: 14px;
              margin-left: 20px;
            }

            .hover-details {
              padding: 20px 20px 20px 20px;

              .big-changel-title-hover {
                font-size: 22px;
              }

              .big-changel-title {
                font-size: 20px;
                font-weight: 500px;
              }

              .big-changel-description {
                font-size: 17px;
              }

              .start-watch-btn {
                width: 150px;
                height: 35px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: #FFF solid 1px;
                color: #FFF;
                font-size: 12px;
                margin-top: 20px;

                position: relative;
                overflow: hidden;

                .overlay {
                  position: absolute;
                  width: 100%;
                  height: 35px;
                  left: -270px;
                }

                &:hover {
                  transition: 1s;
                  -webkit-transition: all .3s ease-in-out;
                  color: #000;

                  .btn-txt {
                    z-index: 9;
                  }

                  .overlay {
                    z-index: 1;
                    left: 0;
                    margin-left: 0;
                    transition: left 300ms ease-out;
                    background-color: #FFF;
                  }
                }
              }
            }
          }
        }
      }

      .chanel-body {
        display: flex;
        align-items: center;
        justify-content: center;

        .chanel-item {
          padding: 0px;
          width: 100%;
          height: 300px;

          .chanel-img-container {
            width: 100%;
            height: 150px;
            overflow: hidden;

            .chanel-img {
              width: 100%;
            }
          }

          .play-btn-bg {
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 28%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #23A4EF;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;

            .play-icon {
              font-size: 30px;
              color: #FFF;
            }
          }

          .small-chanel-descripton-part {
            padding: 15px 25px 0px 0px;

            .small-chanel-category {
              font-size: 13px;
            }

            .small-chanel-title {
              font-size: 20px;
              font-weight: 400;
            }
          }

          &:hover {
            cursor: pointer;
            margin-top: -150px;
            -ms-transform: scale(1.1);
            /* IE 9 */
            -webkit-transform: scale(1.1);
            /* Safari 3-8 */
            transform: scale(1.15);
            transition: 1s;
            z-index: 9;
          }

          .small-chanel-descripton-part-hover {
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.5);
            background: white;
            padding: 20px;

            .small-chanel-hover-category {
              font-size: 12px;
            }

            .small-chanel-hover-title {
              font-size: 20px;
              font-weight: 400;
            }

            .small-chanel-hover-rule {
              font-size: 12px;
              margin-top: 20px;
              font-weight: 500;
            }

            .small-chanel-hover-description {
              font-size: 14px;
              margin-top: 15px;
            }

            .start-watch-btn {
              width: 150px;
              height: 35px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $secondary-bg;
              color: #FFF;
              font-size: 12px;
              margin-top: 20px;
            }
          }
        }
      }
    }

  }
}

@media screen and (max-width: 991px) {
  .watch-component {
    h2 {
      font-size: 24px;
    }

    .body {
      margin-top: 30px;

      .chanel-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .big-chanel-img {
          padding: 0px;
          background-size: cover;
          background-position-y: -62px;

          .big-chanel-item {
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0), $secondary-bg);

            padding: 100px 0px 20px 20px;
            color: #FFF;

            .big-changel-title-category {
              font-size: 14px;
            }

            .big-changel-title {
              font-size: 20px;
              font-weight: 500px;
            }

            .start-watch-btn {
              width: 150px;
              height: 35px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: #FFF solid 1px;
              color: #FFF;
              font-size: 12px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

}