@import '../../../theme.scss';

.clientfeedback-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    position: relative;
    height: 100%;

    .client-item {
        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        height: 100%;
        background: white;
        position: relative;
        z-index: 1;
        .client-header {
            display: flex;
            .avatar {
                width: 66px;
                height: 66px;
                margin-right: 15px;
            }            
            .user-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                font-size: 15px;
                .name {
                    font-weight: bold;
                }
                .sub-info {
                    color: #7B88A0;
                    margin-top: 14px;
                    display: flex;
                    align-items: center;
                    .subject {
                        margin-right: 6px;
                    }
                    .star-container {
                        vertical-align: top !important;
                    }
                }
            }
        }
        .client-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 15px;
            flex: 1;

            .description {
                line-height: 23px;
                margin-top: 15px;
                padding-bottom: 20px;
            }

            .comment-date {
                color: #7B88A0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .clientfeedback-component {
        .client-item {
            padding: 26px 30px;
            .client-header {
                
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .clientfeedback-component {
        .client-item {
            padding: 12px 15px;
            .client-header {
                
            }
        }
    }
}