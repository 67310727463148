@import '../../../theme.scss';

.events-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .events-container {
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;
        }

        .main-row {
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            .left-area {
                padding-left: 0px;

                .image-container {
                    // position: relative;
                    width: 100%;
                    height: 100%;
                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        border-top-left-radius: 10px;
                    }
                }

            }

            .right-area {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding: 25px 15px;

                .event-name {
                    font-size: 28px;
                    font-weight: bold;
                }

                .date-location-category {
                    display: flex;
                    align-items: center;
                    .icon-container {
                        width: 20px;
                    }
                    .category-title {
                        font-size: 15px;
                        font-weight: bold;
                        margin-left: 10px;
                    }

                    .date-location-txt {
                        font-size: 15px;
                        font-weight: normal;
                    }
                }

                .events-details {
                    font-size: 15px;                    
                }


            }
        }
    }
}

@media screen and (min-width: 992px) {
    .events-component {
        padding: 50px 0;
        .events-container {
            padding: 0px;

            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                margin: 30px 0px;
                .left-area {
                    img {
                        border-bottom-left-radius: 10px;
                    }
                }

                .right-area {
                    padding-right: 40px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .events-component {
        padding: 0px 0 62px;
        .events-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                margin: 10px 0;
                .left-area {
                    padding-right: 0px;
                    img {
                        border-top-right-radius: 10px;
                    }
                }
                .right-area {
                    margin-top: 20px;
                    .date-location-category {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}