@import '../../../theme.scss';

.newpassword-component {
    font-family: Maax;
    color: $secondary-text;
    display: flex;
    flex-direction: column;

    h1 {
        margin: 0;
        font-weight: bold;
    }

    .action-area {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sign-up {
            a {
                &.active {
                    text-decoration: none;
                }
            }
        }

        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }

            &.active {
                text-decoration: none;
            }
        }

        .start-btn {
            width: 100%;
        }
    }
}

@media screen and (min-width: 992px) {
    .newpassword-component {
        h1 {
            font-size: 36px;
        }

        .action-area { 
            a {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .newpassword-component {
        h1 {
            font-size: 26px;
            text-align: center;
        }

        .action-area {
            flex-direction: column-reverse;
            a {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
}