@import '../../../theme.scss';

.ws-resource-list-component {

    .component-wrapper {
        padding-bottom: 4rem;
        padding-top: 36px;

        h2 {
            font-size: 32px;
            font-weight: 700;
            white-space: pre-line;
            line-height: 60px;
        }
    }

    .load-more-btn {
        margin-top: 30px;
        margin-bottom: 20px;
        display: table;
        margin-left: auto;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
    }
}

.worksheet-modal {
    
    .modal-content {
        min-height: 10rem;
        border: none;
        padding: 20px;
        border-radius: 0px;
        margin-top: 12rem;

        .header-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -224px;

            img {
                width: 316px;
            }

            h4 {
                text-align: center;
                font-weight: 600;
                margin-top: 20px;
                color: $blue600;
            }
        }

        .form-section {
            margin-top: 30px;

            h6 {
                color: $dark-color;
                font-weight: 600;
            }

            .user-type {
                margin-top: 20px;

                .selection-list {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 -5px;

                    .radio-item {
                        padding: 14px 20px;
                        display: flex;
                        align-items: center;
                        border: 1px solid #E7EBEE;
                        font-size: 14px;
                        flex: 1;
                        margin: 5px;
                        transition: ease-in 0.3s;

                        .name {
                            color: $third-text;
                        }

                        .circle-out {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            padding: 2px;
                            border: 1px solid #E7EBEE;
                            margin-right: 10px;
                        }

                        &.selected {

                            .name {
                                color: $blue600;
                            }

                            .circle-out {
                                border: 1px solid $blue800;
                        
                                .circle-in {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: $blue800;
                                }
                            }
                        } 
                    }
                }
            }

            .form-group {
                margin-top: 15px;

                .form-control {
                    border-radius: 0;
                    padding: 25px 20px;
                    background-color: #E7EBEE;
                    border: none;
                    font-size: 15px;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }

                    &::placeholder {
                        color: #252766;
                        font-size: 15px;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .modal {
        &.worksheet-modal {
            display: none;

            .modal-content {
                padding: 20px 10px;
        
                .selection-list {
                    flex-wrap: wrap;
                }
            }
        }
    }
}