@import '../../theme.scss';

.siteinfo-page {
    display: flex;
    flex-direction: column;
    font-family: Maax;
    color: $secondary-text;

    .siteinfo-container {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-weight: bold;
        }

        .siteinfo-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: white;

            .siteinfo {
                display: flex;
                flex-direction: column;
                margin-bottom: 49px;

                .siteinfo-title {
                    font-weight: bold;
                    font-size: 20px;
                }

                .siteinfo-content {
                    font-size: 15px;       
                    line-height: 22px;
                    text-align:justify;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .siteinfo-page {
        .siteinfo-container {
            max-width: 930px;
        }
    }
}

@media screen and (min-width: 992px) {
    .siteinfo-page {
        padding: 103px 0 120px;
        .siteinfo-container {
            .title {
                font-size: 45px;
                line-height: 57px;
                margin-bottom: 103px;
            }

            .siteinfo-list {
                padding: 54px 90px 52px 30px;

                .siteinfo {
                    .siteinfo-title {
                        margin-bottom: 22px;
                        line-height: 24px;
                    }
                    
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .siteinfo-page {
        padding: 66px 0 88px;
        .siteinfo-container {
            .title {
                font-size: 34px;
                line-height: 45px;
                margin-bottom: 66px;
            }

            .siteinfo-list {
                padding: 52px 13px 51px 13px;

                .siteinfo {
                    .siteinfo-title {
                        margin-bottom: 17px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}