@import '../../theme.scss';

.university-page {
    color: $dark-color;

    .alphabet-content {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        .alpha-item {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-bottom: 15px;
        }
        .active {
            background-color: $primary-color;
            font-weight: bold;
            color: white;
        }
    }
    .university-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: scroll;
        .university-item {
            cursor: pointer;
        }
    }    
}

@media screen and (min-width: 992px) {
    .university-page {
        height: 100vh;
        margin-top: -87px;
        padding-top: 87px;        

        h1 {
            font-size: 24px;
            margin-top: 41px;
            margin-bottom: 20px;
        }
        
        .alphabet-content {            
            .alpha-item {
                font-size: 24px;
                margin-right: 30px;
                width: 50px;
                height: 50px;
                border-radius: 50%;               
            }   
            
        }
        .university-list {
            max-height: calc(100vh - 400px);
        }
    }
}

@media screen and (max-width: 991px) {
    .university-page {
        height: 100vh;
        margin-top: -70px;
        padding-top: 70px;
        
        h1 {
            font-size: 18px;
            margin-top: 62px;
            margin-bottom: 40px;
        }
        .alphabet-content {            
            .alpha-item {
                font-size: 15px;
                margin-right: 20px;
                width: 26px;
                height: 26px;
                border-radius: 50%;               
            }            
        }
        .university-list {
            height: calc(100vh - 400px);
        }
    }
}