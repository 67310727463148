@import '../../../theme.scss';

.tutors-city-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .tutors-city-container {
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;
        }

        .main-tutor-area {
            
        }

        .load-more-btn {
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 2px #23A4EF;
            width: 150px;
            height: 40px;
            border-radius: 5px;
            color: #23A4EF;
        }

        .start-btn {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .tutors-city-component {
        padding: 50px 0;
        .tutors-city-container {
            padding: 0px;

            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-tutor-area {
                margin: 30px 0px;
                
            }

            .start-btn {
                margin-top: 120px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .tutors-city-component {
        padding: 0px 0 62px;
        .tutors-city-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }

            .main-tutor-area {
                margin: 10px 0;
                
            }

            .start-btn {
                margin-top: 74px;
            }
        }
    }
}