@import '../../../theme.scss';

.ourcore-v2-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    // background: #F9FAFB;

    .ourcore-container {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-weight: bold;
        }

        .main-row {
            .left-area {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 80%;
                }
            }
            .right-area {
                display: flex;
                flex-direction: column;

                .core-explain {
                    display: flex;
                    margin-bottom: 22px !important;

                    img {
                        margin-right: 11px;
                        width: 20px;
                        height: 20px;
                    }

                    .explain {
                        font-size: 15px;
                        line-height: 24px;
                    }

                    &:nth-of-type(4) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .ourcore-v2-component {
        padding: 70px 0;
        .ourcore-container {
            .title {
                font-size: 36px;
                margin-bottom: 48px;
            }
            .main-row {
                .left-area {
                    img {
                        margin-left: -35px;
                    }
                }
                .right-area {
                    padding-left: 30px;
                    .start-btn {
                        margin-top: 45px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ourcore-v2-component {
        padding: 5px 0;
        .ourcore-container {
            .title {
                font-size: 24px;
                margin-bottom: 21px;
            }
            .main-row {
                .left-area {
                    margin-bottom: 30px;
                }

                .right-area {
                    .start-btn {
                        margin: 20px auto 0 auto;
                    }
                }
            }
        }
    }
}