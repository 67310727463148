@import '../../../theme.scss';

.video-startarea-component {
  font-family: Maax;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;


  .video-title {
    margin-top: 150px;
  }

  .video-startarea-container {
    background: linear-gradient(rgba(0, 0, 0, 0), #000);
    height: 100%;

    .logo-img {
      width: 220px;
    }

    .video-title {
      margin-top: 50px;
      padding: 0px;

      .channel-logo {
        width: 300px;
        margin-top: 50px;
      }
    }

    .btn-area {
      margin-top: 70px;

      .normal-btn {
        height: 60px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          -ms-transform: scale(1.2);
          /* IE 9 */
          -webkit-transform: scale(1.2);
          /* Safari 3-8 */
          transform: scale(1.15);
          transition: 0.5s;
        }

        .play-icon {
          color: #FFF;
          font-size: 25px;
        }
      }

      .play-btn {
        background-color: $secondary-bg;
      }

      .subscribe-btn {
        background-color: rgb(51, 51, 51);
      }

    }
  }
}

@media screen and (min-width: 992px) {
  .video-startarea-component {
    // background: no-repeat url('../../../Assets/Tv/header-bg.jpg');
    background-position: center;
    background-size: 100% 100%;
    height: 100%;

    h1 {
      font-size: 38px;
    }

    h4 {
      margin-top: 30px;
      font-size: 25px;
      font-weight: 400;
    }

    .video-startarea-container {
      padding: 100px;

      .video-start-header {
        display: flex;
        justify-content: flex-end;
      }

      .btn-area {
        display: flex;

        .normal-btn {
          width: 200px;
        }

        .subscribe-btn {
          width: 270px;
          // margin-left: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .video-startarea-component {
    // background: no-repeat url('../../../Assets/Tv/header-bg.jpg');
    background-position: right;
    height: 100%;

    h1 {
      font-size: 25px;
    }

    h4 {
      margin-top: 30px;
      font-size: 18px;
      font-weight: 400;
    }

    .video-startarea-container {
      padding: 15px;

      .logo-img {
        width: 170px;
      }

      
      .video-title {
        margin-top: 15px;
        .channel-logo {
          width: 250px;
        }
      }

      .btn-area {
        margin-top: 30px;

        .normal-btn {
          width: 100%;
        }
      }
    }
  }
}