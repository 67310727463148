@import '../../theme.scss';

.timezone-component {
    min-width: 345px;
    width: fit-content;
    position: relative;
    cursor: pointer;
    background: white;

    .timezone-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        outline: none;
        background-color: white;
        height: 30px;
        border-radius: 3px;
        font-size: 13px;
        color: #203662;
        padding: 0 7px;
        .start-icon {
            fill: #C5CBD7;
            margin-right: 11px;
        }
        .last-icon {
            margin-left: 15px;
        }

        p {
            width: fit-content;
        }
        svg {
            fill: #ADB1B5;
            margin-left: 5px;
        }
    }

    ::-webkit-scrollbar-track {
        background: white;
    }

    .options {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 15;
        margin-top: 6px;
        border: 2px solid #E7EBEE;
        border-radius: 4px;
        padding: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        background: white;

        ::placeholder {
            color: $secondary-text;
        }
        
        .option-search {
            border: 1px solid #d0d0d0;
            border-radius: 3px;
            margin-bottom: 10px;
            padding-left: 10px;
        }

        .option-list {
            max-height: 200px;
            overflow-y: auto;
        }

        .option {
            display: flex;
            align-items: center;
            color: #203662;
            background: white;
            padding: 0 7px;
            height: 30px;
            justify-content: space-between;
            p {
                font-size: 12px;
            }

            &:hover {
                color: white;
                background: #203662 !important;
            }
        }
    }
}