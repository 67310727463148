@import '../../../theme.scss';

.reviewsarea-component {
    background: url('../../../Assets/HomeV2/reviews-bg.png') no-repeat;
    background-position: center;
    background-size: cover;
    color: white;
    h1 {
        font-weight: 800;
    }
    .btn-request {
        height: 55px;       
        width: 100%;
        border-radius: 5px;
        background-color: $primary-color;
        font-size: 15px;
        color: #FFF;
        font-weight: 700;
    }
    .owl-theme {
        .owl-stage-outer {
            .owl-stage {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .reviewsarea-component {
        padding: 90px 0;
        h1 {
            font-size: 36px;
            line-height: 46px;
            padding-right: 15px;
            margin-bottom: 31px;
        }
        .arrow {
            position: absolute;
            left: -74px;
            top: 123px;
        }
        .btn-request {
            
        }
        .sub-col {
            justify-content: center;
        }
        .owl-theme {
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                left: -25px;
                right: -25px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .reviewsarea-component {
        padding: 67px 0 75px;
        .sub-col {
            align-items: center;
            margin-bottom: 45px;
        }
        h1 {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 29px;
            text-align: center;
        }
        .start-btn {
            margin: 20px auto 0 auto;
        }
        .owl-theme {
            .owl-stage-outer {
                padding-bottom: 30px;
            }
            .owl-nav {
                margin: 0;
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .reviewsarea-component {
        background-size: 100% 100% !important;
    }
}