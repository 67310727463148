@import '../../theme.scss' ;

.coming-soon-wrapper {
    padding: 11rem 2rem;

    .container {
        .title {
            text-align: center;
            color: $primary-color;
            font-size: 5em;
            font-weight: bold;
        }
    }
}