@import '../../../theme.scss';

.login-component {
    font-family: Maax;
    color: $secondary-text;
    display: flex;
    flex-direction: column;

    h1 {
        margin: 0;
        font-weight: bold;
    }

    .custom-input {
        border-radius: 0;
        margin-top: 30px;

        .input-group-text {
            border-radius: 0;
            background: white;
            border-color: #E7EBEE;
        }

        .input-group-prepend {
            .input-group-text {
                border-right: none;
                padding-left: 20px;
                padding-right: 2px;
            }
        }

        .input-group-append {
            .input-group-text {
                border-left: none;
                padding-left: 2px;

                a {
                    &.active {
                        text-decoration: none;
                    }
                }
            }
        }

        input {
            height: 100%;
            border-radius: 0;
            border-left: none;
            box-shadow: none;
            border-color: #E7EBEE;
            color: $secondary-text;

            &::placeholder {
                color: #A6AFC0;
            }
        }
    }

    .append-input {
        input {
            border-right: none;
        }
    }

    .action-area {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sign-up {
            a {
                &.active {
                    text-decoration: none;
                }
            }
        }

        .start-btn {
            // width: 100%;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &.active {
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .login-component {
        h1 {
            font-size: 36px;
        }

        .custom-input {
            height: 65px;

            input {
                font-size: 20px;
            }
        }

        .action-area {
            a {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .login-component {
        h1 {
            font-size: 26px;
            text-align: center;
        }

        .custom-input {
            height: 50px;

            .input-group-text {
                img {
                    width: 20px;
                }
            }

            input {
                font-size: 15px;
            }
        }

        .action-area {
            flex-direction: column-reverse;

            a {
                width: 100%;
                margin-bottom: 30px;
            }

            .sign-up {
                margin-top: 20px;
            }
        }
    }
}