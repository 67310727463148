@import '../../../theme.scss';

.header-v2-component {
    position: fixed;
    width: 100%;
    color: $dark-color;
    z-index: 10;
    font-family: Maax;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 40px;

    .normal-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .menu {
            display: flex;
            align-items: center;
        }
        .menu-item {
            margin-right: 25px;
            height: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                text-decoration: none;
            }

            &.active {
                font-weight: bold;
            }
        }

        .description {
            font-size: 18px;
        }            

        .sign-up {
            font-size: 14px;
            color: white;
            background-color: $v2-color;
            height: 40px;
        }
        .no-btn {
            background-color: #FFF;
            border: solid 1px $dark-color;
            color: $dark-color;
        }
    }
}
@media screen and (min-width: 992px) {
    .header-v2-component {
        height: 70px;
        .normal-container {         
            height: 100%;
            .sign-up {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .show-mobile {
            display: none !important;
        }
    }
}

@media screen and (max-width: 991px) {
    .header-v2-component {
        height: 100px;
        top: 0;
        
        .normal-container {
            .mobile-logo {
                margin-left: auto;
                margin-right: auto;
            }    
            .sign-up {
                // padding-left: 40px;
                // padding-right: 40px;
                width: 118px;
            }
            .show-web {
                display: none !important;
            }
        }
    }
}

.transform-header-v2 {
    background-color: white;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
}