@import '../../../theme.scss';

.articleheader-component {
    display: flex;
    flex-direction: column;
    font-family: Maax;
    color: $secondary-text;
    position: relative;
    flex: 1;
    margin-bottom: 10px;

    .bg-area {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bg-hide {
        position: absolute;
        background: white;
        width: calc(50% - 300px);
        left: 0;
        top: 0;
        height: 534px;
    }

    .header-container {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;

        .article-info {
            display: flex;
            flex-direction: column;
            background: white;
            position: relative;

            .article-info-bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: white;
                z-index: -1;
            }

            .custom-bg-dot {
                z-index: -1;
                width: initial;
                height: initial;
            }

            .category-area {
                .category {
                    display: inline-block;
                    background: #EEEFF3;
                    border-radius: 15.5px;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }

        .tutor-info {
            display: flex;

            .tutor-details {
                display: flex;
                flex: 1;
                flex-direction: column;

                h1 {
                    display: flex;
                    margin: 0;

                    .birthday {
                        line-height: 20px;
                    }
                }

                span {
                    color: #8692A8;
                    font-weight: normal;
                    font-size: 15px;
                }

                .tutor-detail-sub {
                    display: flex;
                }
            }
        }
    }

    h1 {
        font-weight: bold;
        margin: 0;
    }
}

@media screen and (min-width: 992px) {
    .articleheader-component {
        min-height: 534px;
        .bg-area {
            height: 534px;
        }

        .header-container {
            .article-info {
                width: 581px;
                padding: 31px 71px 30px 31px;
                margin: auto 0 -30px 0;

                .custom-bg-dot {
                    left: 17px;
                    right: -15px;
                    bottom: -10px;
                    top: 15px;
                }

                .category-area {
                    .category {
                        padding: 11px 12px;
                    }
                }

                h1 {
                    font-size: 45px;
                    line-height: 57px;
                    margin: 31px 0 35px;
                }

                .tutor-info {
                    .photo {
                        width: 93px;
                        height: 93px;
                    }

                    .tutor-details {
                        padding: 0 28px;
                        h1 {
                            font-size: 20px;
                            line-height: 20px;
                            margin-top: 3px;
                            align-items: center;

                            .mid-dot {
                                margin: 0 11px;
                            }
                        }

                        .tutor-detail-sub {
                            margin-top: 13px;
                            align-items: center;
                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 9px;
                            }

                            span {
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .articleheader-component {
        min-height: 564px;
        .bg-area {
            height: 564px;
        }

        .header-container {
            padding: 0;
            max-width: 100%;
            .article-info {
                width: calc(100% - 30px);
                padding: 28px 12px 25px 28px;
                margin-top: 282px;

                .custom-bg-dot {
                    left: 37px;
                    right: -15px;
                    bottom: -10px;
                    top: 8px;
                }

                .category-area {
                    .category {
                        padding: 7px 14px;
                    }
                }

                h1 {
                    font-size: 26px;
                    line-height: 34px;
                    margin: 15px 0 19px;
                }

                .tutor-info {
                    .photo {
                        width: 58px;
                        height: 58px;
                    }

                    .tutor-details {
                        padding: 0 29px 0 14px;
                        h1 {
                            font-size: 18px;
                            line-height: 20px;
                            flex-direction: column;

                            .birthday {
                                line-height: 20px;
                                margin-top: 7px;
                            }
                        }

                        .tutor-detail-sub {
                            margin-top: 10px;
                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 11px;
                            }

                            span {
                                line-height: 23px;
                                margin-top: -3px;
                            }

                            &.sub2 {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}