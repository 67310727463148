@import '../../../theme.scss';

.offers-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;

    .offers-container {
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            font-weight: bold;
        }

        .description {
            text-align: center;
            font-size: 20px;
        }

        p {
            font-size: 15px;
            padding-top: 20px;
        }

        h1 {
            // font-weight: bold;
            // font-size: 34px;
            padding-top: 20px;
            margin: 0px 10px;
            color: #23A4EF;
        }

        .main-row {
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            .left-area {
                padding-left: 0px;
                background-color: #e1ecf7;
                border-radius: 10px;

                .image-container {
                    
                    img {
                        position: relative;
                        z-index: 1;
                        border-top-left-radius: 10px;
                    }
                }

            }

            .right-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 25px 15px;

                .offer-title {
                    font-size: 28px;
                    font-weight: bold;
                    text-align: center;
                }

                .offer-detail-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;                    

                    .start-btn {
                        margin: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .offers-component {
        padding: 50px 0;
        .offers-container {
            padding: 0px 100px;

            .title {
                font-size: 34px;
                margin-bottom: 48px;
            }

            .main-row {
                margin: 30px 0px;
                .left-area {
                    
                }

                .right-area {
                    padding-right: 40px;
                    .bonus-credit {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .offers-component {
        padding: 0px 0 62px;
        h1 {
            font-size: 28px;
        }
        p{
            text-align: center;
        }
        .offers-container {
            .title {
                font-size: 28px;
                margin-bottom: 21px;
            }
            .main-row {
                margin: 10px 0;
                .left-area {
                    padding-right: 0px;
                    
                }
                .right-area {
                    margin-top: 20px;
                    
                }
            }
        }
    }
}