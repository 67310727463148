@import '../../../theme.scss';

.partner-component {
    font-family: Maax;
    display: flex;
    flex-direction: column;
    color: $secondary-text;
    position: relative;
    height: 100%;

    .partner-item {
        box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        height: 100%;
        background: white;
        position: relative;
        z-index: 1;
        
        .logo-container {
            display: flex;
            align-items: center;
            justify-content: center;
            
            .item-logo {
                position: relative;
                width: 100%;
                z-index: 1;
                width: 160px;
            }
        }

        .item-title {
            font-size: 20px;
            font-weight: bold;
            padding: 15px 0;
        }
        .item-description {
            font-size: 15px;
        }
    }
}

@media screen and (min-width: 992px) {
    .partner-component {
        .partner-item {
            padding: 26px 30px;
            
        }
    }
}

@media screen and (max-width: 991px) {
    .partner-component {
        .partner-item {
            padding: 12px 15px;
            
        }
    }
}