@import '../../../../theme.scss';

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.flashcard-section {
    width: 100%;
    min-height: 270px;
    max-height: fit-content;

    .toggle-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
    }

    .question-card-wrapper {
        width: 100%;
        min-height: 270px;
        position: relative;
        background: no-repeat url('../../../../Assets/Flashcard/question-bg.png');
        background-size: cover;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        perspective: 1000;
        -moz-perspective: 800;
        -webkit-perspective: 800;
        transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        cursor: pointer;

        &.light {
            background: white !important;
            animation: fadeIn;
            animation-duration: 1s;

            .question-content {
                img {
                    background: $blue600;
                }
            }
        }

        .appear {
            animation: fadeIn;
            animation-duration: 1s;
            display: block;
            z-index: 1;
        }

        &.flipped {
            transform: rotatex(-180deg);
            -moz-transform: rotatex(-180deg);
            -webkit-transform: rotatex(-180deg);

            .question-content {
                backface-visibility: visible;
                -moz-backface-visibility: visible;
                -webkit-backface-visibility: visible;

                .front {
                    display: none;
                }
                
                .back {
                    display: block;
                    animation-name: fadeIn;
                    animation-duration: 1s;
                }
            }
        }

        &.light {
            .question-content {
                h1, h2, h3, h4, h5, h6, span, p, div {
                    color: $black900 !important;
                }
            }
        }

        .question-content {
            backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            padding: 2rem 70px;

            .front {
                display: block;
                text-align: center;
                color: white;
            }

            .back {
                transform: rotatex(180deg);
                -moz-transform: rotatex(180deg);
                -webkit-transform: rotatex(180deg);
                display: none;
                text-align: center;
                color: white;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            span,
            p,
            div {
                color: white !important;
            }

            p {
                font-size: 14px;
            }
        }

        img {
            z-index: 99;

            &.next {
                top: calc(50% - 54px / 2);
                position: absolute;
                right: 10px;
            }

            &.prev {
                top: calc(50% - 54px / 2);
                position: absolute;
                left: 10px;
            }
        }
    }

    .items-shown {
        opacity: 0.6;
        text-align: center;
        margin-bottom: 25px;

        span {
            font-weight: 600;
            color: black;
            opacity: 1;
        }
    }

    .flip-card-btn {
        margin-top: 40px;
        margin-bottom: 20px;
        display: block;
        margin-left: auto;
        text-align: center;
        margin-right: auto;
        background-color: $blue800_10;
        color: $blue800;
    }
}

@media screen and (max-width: 992px) {
    .flashcard-section {
        .question-content {
            padding: 1rem 0 !important;

            img {
                margin-top: 60px;
            }
        }

        img {
            &.next {
                right: 0 !important;
            }

            &.prev {
                left: 0 !important;
            }
        }
    }
}