@import '../../../theme.scss';

.toggle-component {
    position: relative;
    border-radius: 22.5px;
    cursor: pointer;
    background: $primary-text;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.05);
  
    .handle {
        position: absolute;
        top: 3px;
        left: 43px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: white;
        transition: left 0.15s;
        -webkit-transition: left 0.15s;
        -moz-user-select: none;
        -webkit-user-select: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  
    &.disable {
      background: grey !important;
    }
  
    &.off {
        background: #E7EBEE;
        
        .handle {
            left: 3px;
        }
    }
}

@media screen and (min-width: 992px) {
    .toggle-component {
        min-width: 79px;
        height: 39px;

        .handle {
            top: 3px;
            left: 43px;
            width: 33px;
            height: 33px;
        }
      
        &.off {
            .handle {
                left: 3px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .toggle-component {
        min-width: 61px;
        height: 30px;

        .handle {
            top: 2px;
            left: 33px;
            width: 26px;
            height: 26px;
        }
      
        &.off {
            .handle {
                left: 2px;
            }
        }
    }
}